import { AxiosPromise } from 'axios';
import { WorkCenter } from '@/types'; // Add the missing import statement
import axios from '@plugins/axios';

export const API_REF = 'primavera/work-centers';

export async function getWorkCenters(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<WorkCenter[]> {
    return axios.get(`${API_REF}/direct`, {
        params: {
            page,
            limit,
            search,
            filterByIds,
        },
    });
}

export async function getWorkCenter(id: number): AxiosPromise<WorkCenter> {
    return axios.get(`${API_REF}/direct/${id}`);
}

export async function createWorkCenter(workCenter: WorkCenter): AxiosPromise<WorkCenter> {
    return axios.post(API_REF, workCenter);
}

export async function deleteWorkCenters(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/delete-many`, data);
}

