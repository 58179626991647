<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Dados Gerais</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.productionOrder') }}
                </v-label>
                <div>
                    <v-text-field
                        :value="getProductionOrder(props.dailyWork.IDOrdemFabrico)?.OrdemFabrico"
                        :placeholder="$t('dailyWork.fields.productionOrder')"
                        :rules="[rules.required]"
                        disabled></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.operation') }}
                </v-label>
                <div>
                    <v-text-field
                        :value="getOperation(props.dailyWork.IDOrdemFabrico)"
                        :placeholder="$t('dailyWork.fields.operation')"
                        :rules="[rules.required]"
                        disabled></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.workCenter') }}
                </v-label>
                <div>
                    <v-select
                        :items="workCenters"
                        v-model="props.dailyWork.IDCentroTrabalho"
                        :rules="[rules.required]"
                        :placeholder="$t('dailyWork.fields.workCenter')"
                        item-title="CentroTrabalho"
                        item-value="IDCentroTrabalho">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.hourType') }}
                </v-label>
                <div>
                    <v-select
                        :items="props.hoursTypes"
                        v-model="props.dailyWork.IDTipoHora"
                        :rules="[rules.required]"
                        :placeholder="$t('dailyWork.fields.hourType')"
                        item-title="Descricao"
                        item-value="IDTipoHora">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.worker') }}
                </v-label>
                <div>
                    <v-select
                        :items="props.workers"
                        v-model="props.dailyWork.IDOperador"
                        :rules="[rules.required]"
                        :placeholder="$t('dailyWork.fields.worker')"
                        item-title="Nome"
                        item-value="IDOperador">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.shift') }}
                </v-label>
                <div>
                    <v-text-field
                        v-model="props.dailyWork.Turno"
                        :placeholder="$t('dailyWork.fields.shift')"></v-text-field>
                </div>
            </v-col>
            <!-- Duração -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('dailyWork.fields.duration') }}: </v-label>
            </v-col>
            <!-- Durações (Fake) -->
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.startDate') }}
                </v-label>
                <div>
                    <v-text-field
                        type="datetime-local"
                        :rules="[rules.required]"
                        v-model="props.dailyWork.DataIni"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.endDate') }}
                </v-label>
                <div>
                    <v-text-field
                        type="datetime-local"
                        :rules="[rules.required]"
                        v-model="props.dailyWork.DataFim"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.duration') }}
                </v-label>
                <div>
                    <v-text-field
                        :value="getDuration(props.dailyWork.TempoConsumido)"
                        :placeholder="$t('dailyWork.fields.duration')"
                        :rules="[rules.required]"
                        disabled></v-text-field>
                </div>
            </v-col>
            <!-- Durações (Real) -->
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.realStartDate') }}
                </v-label>
                <div>
                    <v-text-field
                        type="datetime-local"
                        :rules="[rules.required]"
                        v-model="props.dailyWork.CDU_DataIniReal"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.realEndDate') }}
                </v-label>
                <div>
                    <v-text-field
                        type="datetime-local"
                        :rules="[rules.required]"
                        v-model="props.dailyWork.CDU_DataFimReal"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.realDuration') }}
                </v-label>
                <div>
                    <v-text-field
                        :value="getDuration(props.dailyWork.CDU_TempoConsumidoReal)"
                        :placeholder="$t('dailyWork.fields.realDuration')"
                        :rules="[rules.required]"
                        disabled></v-text-field>
                </div>
            </v-col>
            <!-- Quantidades -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('dailyWork.fields.quantities') }}: </v-label>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.produced') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="props.dailyWork.QtFabricada"
                        :placeholder="$t('dailyWork.fields.produced')"
                        :rules="[rules.required]"
                        disabled></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.conforming') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="quantityConform"
                        :placeholder="$t('dailyWork.fields.conforming')"
                        :rules="[rules.required]"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.rejected') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="props.dailyWork.QtRejeitada"
                        :placeholder="$t('dailyWork.fields.rejected')"
                        :rules="[rules.required]"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="8"
                sm="12">
                <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex">
                            <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> {{ $t('dailyWork.fields.stock') }}: </v-label>
                            <v-checkbox-btn
                                color="primary"
                                :disabled="props.dailyWork?.IDCabecSTKEOF"
                                v-model="stock"
                                @update:modelValue="
                                    () => {
                                        if (stock) {
                                            order = getProductionOrder(props.dailyWork.IDOrdemFabrico)?.OrdemFabrico;
                                        } else {
                                            order = '';
                                        }
                                    }
                                "></v-checkbox-btn>
                        </div>
                    </div>
                    <v-text-field
                        :disabled="!stock || props.dailyWork?.IDCabecSTKEOF"
                        label="Lote"
                        v-model="order"
                        hide-details>
                    </v-text-field>
                    <v-label
                        @click="$router.push({ name: 'DailyWorkDocuments' })"
                        class="tw-cursor-pointer tw-font-semibold tw-text-primary md:tw-text-lg">
                        {{ eofDocument }}
                    </v-label>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="8"
                sm="12">
                <div class="tw-flex tw-items-center">
                    <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> {{ $t('dailyWork.fields.derivatives') }}: </v-label>
                    <v-checkbox-btn
                        color="primary"
                        v-model="derivatives"></v-checkbox-btn>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="8"
                sm="12">
                <div class="tw-flex tw-items-center">
                    <div class="tw-flex">
                        <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> {{ $t('dailyWork.fields.componentsConsumption') }}: </v-label>
                        <v-checkbox-btn
                            color="primary"
                            :disabled="props.dailyWork?.IDCabecSTK"
                            v-model="consumption"></v-checkbox-btn>
                    </div>
                    <v-label
                        @click="$router.push({ name: 'DailyWorkDocuments' })"
                        class="tw-cursor-pointer tw-font-semibold tw-text-primary md:tw-text-lg">
                        {{ sofDocument }}
                    </v-label>
                </div>
            </v-col>
            <v-col
                cols="12"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('dailyWork.fields.observations') }}
                </v-label>
                <div>
                    <v-textarea
                        :placeholder="$t('dailyWork.fields.observations')"
                        v-model="props.dailyWork.Observacoes"></v-textarea>
                </div>
            </v-col>
            <v-col cols="12">
                <v-divider class="tw-py-1"></v-divider>
                <div class="tw-mb-2 tw-flex tw-justify-between tw-py-2 tw-text-lg tw-font-bold">
                    <h2>Editor de Stock:</h2>
                    <v-icon
                        class="tw-rounded-md tw-bg-primary tw-p-4 tw-text-xl tw-text-white"
                        @click.prevent="findStockLines()"
                        >mdi-refresh</v-icon
                    >
                </div>
                <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                    <hot-table
                        :data="props.dailyWork.lines"
                        :licenseKey="license"
                        :contextMenu="true"
                        :columns="columns"
                        :afterChange="afterChange"
                        :afterLoadData="afterLoadData"
                        :beforeKeyDown="handleBeforeKeyDown"
                        :rowHeaders="false"
                        :stretchH="'all'"
                        :width="'100%'"
                        :colHeaders="tableHeaders"
                        ref="hotTableComponent"></hot-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    type="submit"
                    color="primary">
                    {{ $t('shared.save') }}
                </v-btn>
                <v-btn
                    color="red"
                    @click="onDelete"
                    class="ml-2"
                    >{{ $t('shared.delete') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import useRules from '@/composables/rules';
    import { ref, computed, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import { getArticles } from '@/api/articles';
    import { updateDailyWork, deleteDailyWork } from '@/api/dailyWorks';
    import { getStockLines } from '@/api/productionOrders';
    import moment from 'moment';
    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';

    registerAllModules();

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const id = useRoute().params.id;

    const props = defineProps(['dailyWork', 'documents', 'productionOrders', 'workers', 'hoursTypes', 'articles', 'warehouses', 'warehouseLocations']);

    // Format dates to be displayed in the input fields
    props.dailyWork.DataIni = moment(props.dailyWork.DataIni).format('YYYY-MM-DDTHH:mm');
    props.dailyWork.DataFim = moment(props.dailyWork.DataFim).format('YYYY-MM-DDTHH:mm');
    props.dailyWork.CDU_DataIniReal = moment(props.dailyWork.CDU_DataIniReal).format('YYYY-MM-DDTHH:mm');
    props.dailyWork.CDU_DataFimReal = moment(props.dailyWork.CDU_DataFimReal).format('YYYY-MM-DDTHH:mm');

    const docTypes = [{ TipoDoc: 'EOF' }, { TipoDoc: 'SOF' }];

    const emit = defineEmits(['update:success']);

    const refresh = () => {
        emit('update:success', true);
    };

    const quantityConform = ref(props.dailyWork.QtFabricada - props.dailyWork.QtRejeitada);

    const stock = ref(props.dailyWork?.IDCabecSTKEOF == null ? false : true);
    const derivatives = ref(props.dailyWork?.IDCabecSTKEDR == null ? false : true);
    const consumption = ref(props.dailyWork?.IDCabecSTK == null ? false : true);

    const rules = useRules();

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    // Watchers
    watch(
        () => props.dailyWork?.lines,
        (newData) => {
            const hotInstance = hotTableComponent?.value?.hotInstance;
            if (hotInstance) {
                hotInstance.loadData(newData);
            }
        },
        { deep: true },
    );

    const searchArticles = async (article: string) => {
        try {
            const response = await getArticles(null, null, article, null);
            props.articles.push(...response.data?.data.filter((a: any) => !props.articles.some((b: any) => b.Artigo === a.Artigo)));
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    const onSubmit = async () => {
        try {
            props.dailyWork.TempoConsumido = moment(props.dailyWork.DataFim).diff(moment(props.dailyWork.DataIni), 'minutes');
            props.dailyWork.CDU_TempoConsumidoReal = moment(props.dailyWork.CDU_DataFimReal).diff(moment(props.dailyWork.CDU_DataIniReal), 'minutes');

            props.dailyWork.QtConforme = quantityConform.value;
            props.dailyWork.QtFabricada = parseFloat(props.dailyWork.QtConforme) + parseFloat(props.dailyWork.QtRejeitada);

            // For each line, remove the empty lines (the ones that don't have an article)
            props.dailyWork.lines = props.dailyWork.lines.filter((doc: any) => doc.article !== '' && doc.article !== null);

            showLoader();
            await updateDailyWork(id as string, props.dailyWork).then(() => {
                $alert.showAlert({
                    type: 'success',
                    text: 'Diário de Trabalho atualizado com sucesso.',
                });
                refresh();
            });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text:
                    error?.response?.data?.error?.message !== undefined || error?.response?.data?.error?.message !== null
                        ? error.response.data.error.message
                        : 'Erro ao atualizar o diário de trabalho. Por favor, tente novamente.',
            });
            console.error(error);
            hideLoader();
        }
    };

    const onDelete = async () => {
        try {
            const result = await confirm.value.open('Confirmação', `Tem a certeza que deseja eliminar o Diário de Trabalho?`, 'Sim', 'Não');
            if (!result) return;
            showLoader();

            await deleteDailyWork(id as string);

            $alert.showAlert({
                type: 'success',
                text: 'Diário de Trabalho eliminado com sucesso.',
            });
            $router.push({ name: 'DailyWorks' });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text:
                    error?.response?.data?.error?.message?.ErrorMessage !== undefined || error?.response?.data?.error?.message?.ErrorMessage !== null
                        ? error.response.data.error.message.ErrorMessage
                        : 'Erro ao eliminar o diário de trabalho. Por favor, tente novamente.',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    const workCenters = computed(() => {
        const productionOrder = props.productionOrders?.find((productionOrder: any) => productionOrder.IDOrdemFabrico === props.dailyWork?.IDOrdemFabrico);
        if (productionOrder?.operations?.length > 0) {
            const operation = productionOrder.operations.find((operation: any) => operation.IDOrdemFabricoOperacao === props.dailyWork?.IDOrdemFabricoOperacao);
            return operation?.workCenters;
        } else {
            return [];
        }
    });

    const eofDocument = computed(() => {
        if (props.documents.length > 0) {
            const eofDocument = props.documents.find((document: any) => document.TipoDoc === 'EOF');
            return eofDocument ? `${eofDocument.TipoDoc}/${eofDocument.Filial}/${eofDocument.Serie}/${eofDocument.NumDoc}` : '';
        }
    });

    const sofDocument = computed(() => {
        if (props.documents.length > 0) {
            const sofDocument = props.documents.find((document: any) => document.TipoDoc === 'SOF');
            return sofDocument ? `${sofDocument.TipoDoc}/${sofDocument.Filial}/${sofDocument.Serie}/${sofDocument.NumDoc}` : '';
        }
    });

    const getProductionOrder = (productionOrderId: string) => {
        const productionOrder = props.productionOrders?.find((productionOrder: any) => productionOrder.IDOrdemFabrico === productionOrderId);
        return productionOrder;
    };

    // Initialize order variable, based on stock and production order
    const order = ref(stock.value ? getProductionOrder(props.dailyWork.IDOrdemFabrico)?.OrdemFabrico : '');

    const getOperation = (productionOrderId: string) => {
        const productionOrder = props.productionOrders?.find((productionOrder: any) => productionOrder.IDOrdemFabrico === productionOrderId);

        if (productionOrder?.operations?.length > 0) {
            const operation = productionOrder.operations.find((operation: any) => operation.IDOrdemFabricoOperacao === props.dailyWork?.IDOrdemFabricoOperacao);
            return operation?.Descricao;
        } else {
            return '';
        }
    };

    const getDuration = (duration: number) => {
        if (duration <= 0) return '';

        const days = Math.floor(duration / 1440); // 1440 minutos em um dia
        const hours = Math.floor((duration % 1440) / 60); // minutos restantes convertidos para horas
        const minutes = duration % 60; // minutos restantes

        return `${days}d:${hours}h:${minutes}m`;
    };

    /**
     *
     *
     * INFO TO HANDSONTABLE
     *
     *
     */

    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);
    const hotTableComponent = ref(null);

    const tableHeaders = ref(['Tipo Doc.', 'Artigo', 'Descrição', 'Armazém', 'Localização', 'Lote', 'Unidade', 'Quantidades']);

    const columns = computed(() => {
        return [
            {
                data: 'docType',
                type: 'dropdown',
                source: createDropdownSource(docTypes, 'TipoDoc'),
            },
            {
                data: 'article',
                type: 'dropdown',
                source: createDropdownSource(props.articles, 'Artigo'),
            },
            {
                data: 'description',
                type: 'text',
            },
            {
                data: 'warehouse',
                type: 'dropdown',
                source: createDropdownSource(props.warehouses, 'Armazem'),
            },
            {
                data: 'warehouseLocation',
                type: 'dropdown',
                source: createDropdownSource(props.warehouseLocations, 'Localizacao'),
            },
            {
                data: 'order',
                type: 'text',
            },
            {
                data: 'unit',
                type: 'text',
            },
            {
                data: 'quantity',
                type: 'numeric',
            },
        ];
    });

    const createDropdownSource = (array: any, key: any) => (array ? array.map((item: any) => item[key]) : []);

    const validateAllCells = () => {
        if (hotTableComponent.value && hotTableComponent.value.hotInstance) {
            hotTableComponent.value.hotInstance.validateCells();
        }
    };

    const handleBeforeKeyDown = async (event) => {
        const hotInstance = hotTableComponent.value.hotInstance;
        const activeEditor = hotInstance.getActiveEditor();
        const selectedRange = hotInstance.getSelectedRange();
        const activeColumn = selectedRange?.[0]?.from?.col;

        const isPrintableCharacter = event.key.length === 1 && event.key.match(/^[\w\d\s\p{L}\p{N}]$/u);

        if (activeColumn === 1 && isPrintableCharacter) {
            const currentValue = activeEditor?.TEXTAREA?.value || '';
            const newValue = currentValue + event.key;

            if (newValue.length >= 3) {
                // Fetch updated articles based on newValue (user input)
                showLoader();
                await searchArticles(newValue);
                hideLoader();

                // Update the dropdown source dynamically
                const dropdownEditor = hotInstance.getActiveEditor();
                dropdownEditor.cellProperties.source = props.articles.map((article) => article.Artigo);
                setTimeout(() => dropdownEditor.open(), 0);
            }
        }
    };

    const afterChange = (changes: any, source: any) => {
        if (source === 'edit' || source === 'CopyPaste.paste') {
            changes.forEach(async ([row, prop, oldValue, newValue]) => {
                if (prop === 'article' && oldValue !== newValue) {
                    const article = props.articles.find((a) => a.Artigo === newValue);
                    if (article) {
                        hotTableComponent.value.hotInstance.setDataAtCell(row, 2, article.Descricao);
                        hotTableComponent.value.hotInstance.setDataAtCell(row, 5, order.value);
                        hotTableComponent.value.hotInstance.setDataAtCell(row, 6, article.UnidadeBase);
                    }
                }
                if (prop === 'warehouse' && oldValue !== newValue) {
                    const warehouse = props.warehouses.find((w) => w.Armazem === newValue);
                    if (warehouse) {
                        const warehouseLocation = props.warehouseLocations.find((wl) => wl.Armazem === warehouse.Armazem);
                        if (warehouseLocation) {
                            hotTableComponent.value.hotInstance.setDataAtCell(row, 4, warehouseLocation.Localizacao);
                        }
                    }
                }
                if (prop === 'docType' && newValue === 'SOF') {
                    hotTableComponent.value.hotInstance.setDataAtCell(row, 5, '<L01>');
                }
            });
        }

        // Insert a new row when the last row is edited and make sure there is at least one column with data
        // So the user can continue to add more rows
        if ((source === 'edit' || source === 'CopyPaste.paste') && changes[0][0] === hotTableComponent.value.hotInstance.countRows() - 1) {
            //check if there is at least one column with data
            const hasData = hotTableComponent.value.hotInstance.getDataAtRow(changes[0][0]).some((cell: any) => cell !== null && cell !== '');
            if (hasData) {
                hotTableComponent.value.hotInstance.alter('insert_row_below', hotTableComponent.value.hotInstance.countRows());
            }
        }
        validateAllCells();
    };

    const afterLoadData = (sourceData, initialLoad, source) => {
        validateAllCells();
    };

    const findStockLines = async () => {
        showLoader();

        const productionOrder = props.productionOrders?.find((productionOrder: any) => productionOrder.IDOrdemFabrico === props.dailyWork.IDOrdemFabrico);
        const operation = productionOrder?.operations?.find((operation: any) => operation.IDOrdemFabricoOperacao === props.dailyWork.IDOrdemFabricoOperacao);
        const quantity = parseFloat(quantityConform.value) + parseFloat(props.dailyWork.QtRejeitada);

        const eofLines = [];
        const sofLines = [];

        if (stock.value) {
            try {
                const response = await getStockLines('EOF', productionOrder.IDOrdemFabrico, operation.Operacao, quantity);
                eofLines.push(...response?.data);
            } catch (error) {
                console.error('Error fetching EOF stock lines:', error);
            }
        }
        if (consumption.value) {
            try {
                const response = await getStockLines('SOF', productionOrder.IDOrdemFabrico, operation.Operacao, quantity);
                sofLines.push(...response?.data);
            } catch (error) {
                console.error('Error fetching SOF stock lines:', error);
            }
        }

        props.dailyWork.lines = [
            ...eofLines.map((line: any) => ({
                docType: 'EOF',
                article: line.Artigo,
                description: line.Descricao,
                warehouse: line.Armazem ? line.Armazem : 'A1',
                warehouseLocation: line.Localizacao ? line.Localizacao : 'A1',
                order: line.Lote ? line.Lote : order,
                unit: line.Unidade ? line.Unidade : '',
                quantity: line.Quantidade,
            })),
            ...sofLines.map((line: any) => ({
                docType: 'SOF',
                article: line.Artigo,
                description: line.Descricao,
                warehouse: line.Armazem ? line.Armazem : 'A1',
                warehouseLocation: line.Localizacao ? line.Localizacao : 'A1',
                order: line.Lote ? line.Lote : '<L01>',
                unit: line.Unidade ? line.Unidade : '',
                quantity: line.Quantidade,
            })),
            {
                docType: '',
                article: '',
                description: '',
                warehouse: '',
                warehouseLocation: '',
                order: '',
                unit: '',
                quantity: '',
            },
        ];

        // Search Articles so they dont appear with red label
        props.dailyWork?.lines.forEach((line: any) => {
            if (line.article) {
                searchArticles(line.article);
            }
        });

        hideLoader();
    };
</script>

<style scoped>
    .hot-table-container {
        height: 150px;
    }
</style>
