<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Dados Gerais</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.article') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.fields.article')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.Artigo"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.alternative') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.fields.alternative')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.Alternativa"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.client') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.fields.client')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.Cliente"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.quantity') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.quantity')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.QtOrdemFabrico"></v-text-field>
                </div>
            </v-col>
            <!-- Quantidades -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('productionOrders.fields.quantities') }}: </v-label>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.qtNeeded') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.qtNeeded')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.QtNecessaria"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.qtProduced') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.qtProduced')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.QtFabricada"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.accordingTo') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.accordingTo')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.Prioridade"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.rejected') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.rejected')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.QtRejeitada"></v-text-field>
                </div>
            </v-col>
            <!-- Custos Previstos -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('productionOrders.fields.expectedCosts') }}: </v-label>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.material') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.material')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.CustoMateriaisPrevisto"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.transformation') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.transformation')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.CustoTransformacaoPrevisto"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.subproducts') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.subproducts')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.CustoSubprodutosPrevisto"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.others') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :placeholder="$t('productionOrders.fields.others')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.OutrosCustosPrevito"></v-text-field>
                </div>
            </v-col>
            <!-- Custos Reais -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('productionOrders.fields.realCosts') }}: </v-label>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.material') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.material')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.CustoMateriaisReal"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.transformation') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.transformation')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.CustoTransformacaoReal"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.subproducts') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.subproducts')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.CustoSubprodutosReal"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.others') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('productionOrders.fields.others')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.OutrosCustosReal"></v-text-field>
                </div>
            </v-col>
            <!-- Datas Previstas -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('productionOrders.fields.expectedDates') }}: </v-label>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.init') }}
                </v-label>
                <div>
                    <v-date-input
                        density="compact"
                        prepend-icon=""
                        hide-details="auto"
                        bg-color="gray-50"
                        variant="outlined"
                        disabled
                        :placeholder="$t('productionOrders.fields.init')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.DataIniPrevista"></v-date-input>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.end') }}
                </v-label>
                <div>
                    <v-date-input
                        density="compact"
                        prepend-icon=""
                        hide-details="auto"
                        bg-color="gray-50"
                        variant="outlined"
                        disabled
                        :placeholder="$t('productionOrders.fields.end')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.DataFimPrevista">
                    </v-date-input>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.delivery') }}
                </v-label>
                <div>
                    <v-date-input
                        density="compact"
                        prepend-icon=""
                        hide-details="auto"
                        bg-color="gray-50"
                        variant="outlined"
                        disabled
                        :placeholder="$t('productionOrders.fields.delivery')"
                        :rules="[rules.required]"
                        v-model="props.productionOrder.DataEntrega"></v-date-input>
                </div>
            </v-col>
            <!-- Datas Reais -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('productionOrders.fields.realDates') }}: </v-label>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.init') }}
                </v-label>
                <div>
                    <v-date-input
                        density="compact"
                        prepend-icon=""
                        hide-details="auto"
                        bg-color="gray-50"
                        variant="outlined"
                        :placeholder="$t('productionOrders.fields.init')"
                        v-model="props.productionOrder.DataIniReal"></v-date-input>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.end') }}
                </v-label>
                <div>
                    <v-date-input
                        density="compact"
                        prepend-icon=""
                        hide-details="auto"
                        bg-color="gray-50"
                        variant="outlined"
                        :placeholder="$t('productionOrders.fields.end')"
                        v-model="props.productionOrder.DataFimReal"></v-date-input>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.fields.observations') }}
                </v-label>
                <div>
                    <v-textarea
                        :placeholder="$t('productionOrders.fields.observations')"
                        v-model="props.productionOrder.Observacoes"></v-textarea>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
                <v-btn
                    color="red"
                    @click="deleteProdOrder"
                    class="ml-2"
                    >{{ $t('shared.delete') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { updateProductionOrder, deleteProductionOrder } from '@/api/productionOrders';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const id = useRoute().params.id;

    const props = defineProps(['productionOrder']);

    const rules = useRules();

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const onSubmit = async () => {
        showLoader();
        await updateProductionOrder(id as string, props.productionOrder);
        $alert.showAlert({
            type: 'success',
            text: 'Ordem de Fabrico atualizada com sucesso.',
        });
        hideLoader();
    };

    const deleteProdOrder = async () => {
        try {
            const result = await confirm.value.open('Confirmação', `A Ordem de Fabrico está confirmada. Tem a certeza que deseja eliminar?`, 'Sim', 'Não');
            if (!result) return;

            showLoader();
            // Disconfirm the production order
            // So then we can delete it
            await updateProductionOrder(id as string, { Confirmada: false }).then(async () => {
                await deleteProductionOrder(id as string);
                $alert.showAlert({
                    type: 'success',
                    text: 'Ordem de Fabrico eliminada com sucesso.',
                });
                $router.push({ name: 'ProductionOrders' });
            });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text:
                    error?.response?.data?.error?.message?.ErrorMessage !== undefined || error?.response?.data?.error?.message?.ErrorMessage !== null
                        ? error.response.data.error.message.ErrorMessage
                        : 'Erro ao eliminar o cálculo de necessidades. Por favor, tente novamente.',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };
</script>

<style scoped></style>
