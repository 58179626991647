<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex">
                            <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Permite Operações em Simultâneo: </v-label>
                            <v-checkbox-btn
                                color="primary"
                                v-model="workCenter.parallelPrograming"></v-checkbox-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col
                cols="12"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Planeamento Automático? </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :disabled="!workCenter.parallelPrograming"
                        color="primary"
                        v-model="workCenter.automaticOperationsPlanning"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true"> {{ $t('shared.yes') }} </v-btn>
                        <v-btn :value="false"> {{ $t('shared.no') }} </v-btn>
                    </v-btn-toggle>
                </div>
                <div class="tw-ml-4 tw-mt-2">
                    <v-radio-group
                        color="primary"
                        v-model="workCenter.isActivatedLoadLimit"
                        :disabled="!workCenter.parallelPrograming || !workCenter.automaticOperationsPlanning">
                        <v-radio
                            label="Sem Limite Máximo de Carga"
                            :value="false"></v-radio>
                        <div class="tw-flex tw-gap-x-2">
                            <v-radio
                                label="Com Limite Máximo de Carga:"
                                :value="true"
                                class="tw-flex-none"></v-radio>
                            <v-text-field
                                type="number"
                                :disabled="!workCenter.parallelPrograming || !workCenter.isActivatedLoadLimit"
                                suffix="%"
                                v-model="workCenter.maxLoadLimit"
                                class="tw-flex tw-w-11"></v-text-field>
                        </div>
                    </v-radio-group>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { Ref, ref } from 'vue';
    import { WorkCenter, WorkCenterSOC } from '@/types';
    import useRules from '@/composables/rules';
    import { VForm } from 'vuetify/lib/components/index.mjs';

    const form = ref<VForm>();

    const rules = useRules();
    const props = defineProps(['workCenter']);

    const workCenter = ref(props.workCenter);
    console.log(workCenter.value);

    const onSubmit = () => {
        console.log('onSubmit');
    };
</script>

<style scoped></style>
