import { AxiosPromise } from 'axios';
import { WorkCenter, ArticleOperation } from '@/types'; // Add the missing import statement
import axios from '@plugins/axios';
import qs from 'qs';

export const API_REF = 'primavera/articles';
export const API_REF_SOC = '/articles';

export async function getArticles(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<any[]> {
    return axios.get(API_REF, {
        params: {
            page,
            limit,
            search,
            filterByIds,
        },
    });

}

export async function getArticle(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

export async function getSocArticleByCode(code: string): AxiosPromise<any> {
    const query = qs.stringify({
        filters: {
            code: {
                $eq: code,
            },
        },
    });

    return axios.get(`${API_REF_SOC}?${query}`);
}

export async function getSocArticlesByCode(codes: string[]): AxiosPromise<any> {
    const query = qs.stringify({
        filters: {
            code: {
                $in: codes,
            },
        },
    });

    return axios.get(`${API_REF_SOC}?${query}`);
}

export async function createArticle(article: any): AxiosPromise<any> {
    return axios.post(API_REF, article);
}


export async function updateArticle(id: string, article: any): AxiosPromise<any> {
    return axios.put(`${API_REF}/${id}`, article);
}

export async function deleteArticles(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/delete-many`, data);
}

export async function loadArticles(articles: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/load/json`, articles);
}

export async function createArticleWithPrice(article: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/prices`, article);
}

export async function loadTechnicalSheetsAutodeskInvetor(technicalSheet: any): AxiosPromise<any[]> {
    console.log('cenas cena sceasn');
    return axios.post(`${API_REF}/operations/load/autodeskInventor`, technicalSheet);
}

export async function getArticleOperations(id: string): AxiosPromise<ArticleOperation[]> {
    return axios.get(`${API_REF}/${id}/operations`);
}

export async function getArticleParents(id: string): AxiosPromise<WorkCenter[]> {
    return axios.get(`${API_REF}/${id}/parents`);
}
