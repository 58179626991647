<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ dailyWork.IDDiarioTrabalho }}</h1>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn
                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="x-large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
        </v-row>
        <router-view
            v-if="done"
            :dailyWork="dailyWork"
            :documents="documents"
            :productionOrders="productionOrders"
            :workers="workers"
            :hoursTypes="hoursTypes"
            :articles="articles"
            :warehouses="warehouses"
            :warehouseLocations="warehouseLocations"
            @update:success="init" />
    </v-container>
</template>

<script setup lang="ts">
    import { ref, watch, computed, onMounted } from 'vue';
    import useRules from '@/composables/rules';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { getDailyWork } from '@/api/dailyWorks';
    import { getInternalDocument } from '@/api/internalDocuments';
    import { getProductionOrders } from '@/api/productionOrders';
    import { getWorkers } from '@/api/workers';
    import { getHoursTypes } from '@/api/hoursTypes';
    import { getArticles } from '@/api/articles';
    import { getWarehouses } from '@/api/warehouses';
    import { getWarehouseLocations } from '@/api/warehouseLocations';
    import { useRouter, useRoute } from 'vue-router';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const $route = useRoute();
    const id = useRoute().params.id;
    const done = ref(false);

    const rules = useRules();

    const navItems = ref([
        { title: 'Detalhes', to: { name: 'DailyWorkDetails' } },
        { title: 'Documentos', to: { name: 'DailyWorkDocuments' } },
    ]);

    const dailyWork = ref({});
    const productionOrders = ref([]);
    const workers = ref([]);
    const hoursTypes = ref([]);
    const documents = ref([]);
    const articles = ref([]);
    const warehouses = ref([] as any[]);
    const warehouseLocations = ref([] as any[]);

    const init = async () => {
        try {
            showLoader();
            await getDailyWork(id as string)
                .then(async (response) => {
                    dailyWork.value = response.data;
                    console.log('DailyWork', dailyWork.value);
                    const [productionOrdersResponse, workersResponse, hoursTypesResponse, warehousesResponse, warehouseLocationsResponse] = await Promise.all([
                        getProductionOrders(null, null, null),
                        getWorkers(),
                        getHoursTypes(),
                        getWarehouses(),
                        getWarehouseLocations(),
                    ]);

                    productionOrders.value = productionOrdersResponse.data?.data;
                    workers.value = workersResponse.data;
                    hoursTypes.value = hoursTypesResponse.data;
                    warehouses.value = warehousesResponse.data;
                    warehouseLocations.value = warehouseLocationsResponse.data;

                    documents.value = [];

                    // If the daily work has internal documents, get them
                    if (dailyWork.value.IDCabecSTK != null) {
                        const response = await getInternalDocument(dailyWork.value.IDCabecSTK);
                        documents.value.push(response.data);
                    }
                    if (dailyWork.value.IDCabecSTKEOF != null) {
                        const response = await getInternalDocument(dailyWork.value.IDCabecSTKEOF);
                        documents.value.push(response.data);
                    }
                    if (dailyWork.value.IDCabecSTK == null && dailyWork.value.IDCabecSTKEOF == null) {
                        documents.value = [];
                    }

                    // Handle Lines, used in Stock Editor
                    const lines = [];
                    for (let document of documents.value) {
                        const linhasInternos = document?.LinhasInternos;
                        for (let linhaInterno of linhasInternos) {
                            if (linhaInterno.Artigo != null) {
                                const filterByIds = { Artigo: [linhaInterno.Artigo] };
                                const response = await getArticles(null, null, null, filterByIds);
                                articles.value = [...articles.value, ...response.data.data];
                            }
                            const article = articles.value.find((article: any) => article.Artigo == linhaInterno.Artigo);
                            lines.push({
                                docType: document?.TipoDoc,
                                article: article?.Artigo,
                                description: article?.Descricao,
                                warehouse: linhaInterno.Armazem,
                                warehouseLocation: linhaInterno.Localizacao,
                                order: linhaInterno.Lote,
                                unit: linhaInterno.Unidade,
                                quantity: Math.abs(linhaInterno.Quantidade),
                            });
                        }
                    }
                    if (lines.length > 0) {
                        dailyWork.value.lines = lines;
                    } else {
                        dailyWork.value.lines = [
                            {
                                docType: '',
                                article: '',
                                description: '',
                                warehouse: 'A1',
                                warehouseLocation: 'A1',
                                order: '',
                                unit: '',
                                quantity: '',
                            },
                        ];
                    }
                })
                .finally(() => {
                    done.value = true;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar os dados. Por favor, tente novamente.',
            });
        }
    };

    init();
</script>

<style scoped></style>
