export default function usePrimaveraParser() {
    const parseSocToPrimaveraWorkCenter = (body: any) => {
        return {
            IDCentroTrabalho: body.id ? body.id : null,
            CentroTrabalho: body.name ? body.name : '',
            Descricao: body.description ? body.description : '',
            IDSeccao: body.section ? body.section : 0,
            NrTurnos: body.nShifts ? body.nShifts : 0,
            CoeficienteEficiencia: body.efficiency ? body.efficiency : 0,
            Ordem: body.nOrder ? body.nOrder : 0,
            Observacoes: body.obs ? body.obs : '',
            CustoHoraMaquinaCalculado: body.calculatedMachineCost ? body.calculatedMachineCost : 0,
            CustoHoraMaquinaRevisto: body.reviewedMachineCost ? body.reviewedMachineCost : 0,
            CustoHoraOperadorCalculado: body.calculatedOperatorCost ? body.calculatedOperatorCost : 0,
            CustoHoraOperadorRevisto: body.reviewedOperatorCost ? body.reviewedOperatorCost : 0,
            TempoPreparacao: body.preparationTime ? body.preparationTime : 0,
            TempoEspera: body.waitTime ? body.waitTime : 0,
            NrOperadores: body.nOperators ? body.nOperators : 0,
            Armazem: body.warehouse ? body.warehouse : 'A1',
            PicagemQtIni: body.registerQuantitiesInInit ? body.registerQuantitiesInInit : false,
            PicagemQtFim: body.registerQuantitiesInEnd ? body.registerQuantitiesInEnd : false,
            PicagemQtDurante: body.registerQuantitiesDuring ? body.registerQuantitiesDuring : false,
            IniAuto: body.iniAuto ? body.iniAuto : false,
            FimAuto: body.endAuto ? body.endAuto : false,
            EmModoEdicao: false,
            // CodigoTabLog: 'Teste CodigoTabLog',
            // ChaveLog: 'Teste ChaveLog',
            // Conteudo: 'Teste Conteudo',
            // EstadoBE: 'Teste EstadoBE',
            Localizacao: body.location ? body.location : '',
            CalendarioTrabalho: body.workCalendar ? body.workCalendar : '',
            FatorCapacidade: body.capacityFactor ? body.capacityFactor : 0,
            ProgramacaoParalela: body.parallelPrograming ? body.parallelPrograming : false,
            ArmazemConsumoComponentes: body.componentWarehouse ? body.componentWarehouse : 'A1',
            LocalizacaoArmazemConsumoComponentes: body.componentWarehouseLocation ? body.componentWarehouseLocation : 'A1',
            ArmazemEntradaProdutos: body.productEntranceWarehouse ? body.productEntranceWarehouse : 'A1',
            LocalizacaoArmazemEntradaProdutos: body.productEntranceWarehouseLocation ? body.productEntranceWarehouseLocation : 'A1',
            ArmazemRejeitados: body.rejectedProductWarehouse ? body.rejectedProductWarehouse : 'A1',
            LocalizacaoArmazemRejeitados: body.rejectedProductWarehouseLocation ? body.rejectedProductWarehouseLocation : 'A1',
            PlaneamentoAutomaticoOperacoesEmSimultaneo: body.automaticOperationsPlanning ? body.automaticOperationsPlanning : false,
            LimiteDeCargaMaximoAtivo: body.isActivatedLoadLimit ? body.isActivatedLoadLimit : false,
            LimiteDeCargaMaximo: body.maxLoadLimit ? body.maxLoadLimit : 0,
            TipoDistribuicaoTempos: body.timesDistributionTypes ? body.timesDistributionTypes : 0,
            // AbvtApl: 'Teste AbvtApl',
            // Categoria: 'Teste Categoria',
            // DescricaoEntidade: 'teste DescricaoEntidade',
        };
    };

    const parsePrimaveraToSocWorkCenter = (body: any) => {
        console.log(body);
        return {
            id: body.IDCentroTrabalho ? body.IDCentroTrabalho : null,
            name: body.CentroTrabalho ? body.CentroTrabalho : '',
            description: body.Descricao ? body.Descricao : '',
            section: body.IDSeccao ? body.IDSeccao : 0,
            nShifts: body.NrTurnos ? body.NrTurnos : 0,
            efficiency: body.CoeficienteEficiencia ? body.CoeficienteEficiencia : 0,
            nOrder: body.Ordem ? body.Ordem : 0,
            obs: body.Observacoes ? body.Observacoes : '',
            calculatedMachineCost: body.CustoHoraMaquinaCalculado ? body.CustoHoraMaquinaCalculado : 0,
            reviewedMachineCost: body.CustoHoraMaquinaRevisto ? body.CustoHoraMaquinaRevisto : 0,
            calculatedOperatorCost: body.CustoHoraOperadorCalculado ? body.CustoHoraOperadorCalculado : 0,
            reviewedOperatorCost: body.CustoHoraOperadorRevisto ? body.CustoHoraOperadorRevisto : 0,
            preparationTime: body.TempoPreparacao ? body.TempoPreparacao : 0,
            waitTime: body.TempoEspera ? body.TempoEspera : 0,
            nOperators: body.NrOperadores ? body.NrOperadores : 0,
            warehouse: body.Armazem ? body.Armazem : null,
            registerQuantitiesInInit: body.PicagemQtIni ? body.PicagemQtIni : false,
            registerQuantitiesInEnd: body.PicagemQtFim ? body.PicagemQtFim : false,
            registerQuantitiesDuring: body.PicagemQtDurante ? body.PicagemQtDurante : false,
            iniAuto: body.IniAuto ? body.IniAuto : false,
            endAuto: body.FimAuto ? body.FimAuto : false,
            location: body.Localizacao ? body.Localizacao : '',
            workCalendar: body.CalendarioTrabalho ? body.CalendarioTrabalho : '',
            capacityFactor: body.FatorCapacidade ? body.FatorCapacidade : 0,
            parallelPrograming: body.ProgramacaoParalela ? body.ProgramacaoParalela : false,
            componentWarehouse: body.ArmazemConsumoComponentes ? body.ArmazemConsumoComponentes : null,
            componentWarehouseLocation: body.LocalizacaoArmazemConsumoComponentes ? body.LocalizacaoArmazemConsumoComponentes : null,
            productEntranceWarehouse: body.ArmazemEntradaProdutos ? body.ArmazemEntradaProdutos : null,
            productEntranceWarehouseLocation: body.LocalizacaoArmazemEntradaProdutos ? body.LocalizacaoArmazemEntradaProdutos : null,
            rejectedProductWarehouse: body.ArmazemRejeitados ? body.ArmazemRejeitados : null,
            rejectedProductWarehouseLocation: body.LocalizacaoArmazemRejeitados ? body.LocalizacaoArmazemRejeitados : null,
            automaticOperationsPlanning: body.PlaneamentoAutomaticoOperacoesEmSimultaneo ? body.PlaneamentoAutomaticoOperacoesEmSimultaneo : false,
            isActivatedLoadLimit: body.LimiteDeCargaMaximoAtivo ? body.LimiteDeCargaMaximoAtivo : false,
            maxLoadLimit: body.LimiteDeCargaMaximo ? body.LimiteDeCargaMaximo : 0,
            timesDistributionTypes: body.TipoDistribuicaoTempos ? body.TipoDistribuicaoTempos : 0,
            machineCosts: body.machineCosts || [],
            operators: body.operators || [],
        };
    };

    const parseSocToPrimaveraArticle = (body: any) => {
        console.log(body);
        return {
            Artigo: body.article || '',
            Descricao: body.description || '',

            UnidadeBase: body.unit || '',
            UnidadeVenda: body.sellUnit || '',
            UnidadeCompra: body.buyUnit || '',
            UnidadeEntrada: body.entranceUnit || '',
            UnidadeSaida: body.exitUnit || '',
            FactorConversao: body.convertionFactor || 1,
            Peso: body.weight || 0,
            Volume: body.volume || 0,
            IVA: body.IVA || 0,
            MovStock: body.moveStocks || 'N',
            UtilizadoGPR: body.usedInProduction || false,
            IdTipoOrigem: body.GPROriginType || 0,
            Familia: body.family || '',
            TipoArtigo: body.typeArticle || '',
            DeduzIVA: body.IvaIncluded || false,
            SujeitoDevolucao: body.allowsReturns || false,
            PCPadrao: body.standardCostPrice || 0,
            TrataLotes: body.batchTreatment || false,
            Observacoes: body.observations || '',
            CalcNecessidades: body.calcNeeds || false,
            EmModoEdicao: false,
            PCMedio: body.meanCostPrice || 0,
            PCUltimo: body.lastCostPrice || 0,
            FabCompAuto: body.automaticComponentsProduction || false,
            IdTipoProducao: body.productionType || null,
            Prices: {
                Artigo: body.article || '',
                Moeda: 'EUR',
                Unidade: body.unit || '',
                Descricao: body.description || '',
                PVP1: body.price || 0,
                PVP1IvaIncluido: true,
                PVP2: 0,
                PVP2IvaIncluido: true,
                PVP3: 0,
                PVP3IvaIncluido: true,
                PVP4: 0,
                PVP4IvaIncluido: true,
                PVP5: 0,
                PVP5IvaIncluido: true,
                PVP6: 0,
                PVP6IvaIncluido: true,
                EmModoEdicao: false,
            },
        };
    };

    const parsePrimaveraToSocArticle = (body: any) => {
        console.log(body);
        return {
            article: body.Artigo || '',
            description: body.Descricao || '',
            unit: body.UnidadeBase || '',
            sellUnit: body.UnidadeVenda || '',
            buyUnit: body.UnidadeCompra || '',
            entranceUnit: body.UnidadeEntrada || '',
            exitUnit: body.UnidadeSaida || '',
            weight: body.Peso || 0,
            volume: body.Volume || 0,
            IVA: body.Iva || 0,
            moveStocks: body.MovStock || 'N',
            usedInProduction: body.UtilizadoGPR || false,
            GPROriginType: body.IDTipoOrigemGPR || 0,
            family: body.Familia || '',
            typeArticle: body.TipoArtigo || '',
            IvaIncluded: body.DeduzIvaNoImo || false,
            allowsReturns: body.PermiteDevolucao || false,
            batchTreatment: body.TratamentoLotes || false,
            observations: body.Observacoes || '',
            calcNeeds: body.CalcNecessidadesGPR || false,
            meanCostPrice: body.PCMedio || 0,
            lastCostPrice: body.PCUltimo || 0,
            price: body.PVP1 || 0,
            pvpIvaIncluded: true,
            convertionFactor: body.FactorConversao || null,
            automaticComponentsProduction: body.FabCompAuto || body.FabCompAutoGPR || null,
            productionType: body.IDTipoProducao || body.IDTipoProducaoGPR || null,
            standardCostPrice: body.PCPadrao || 0,
        };
    };

    const parsePrimaveraInternalDocumentToSoc = (body: any) => {
        return {
            serie: body.Serie || '',
            documentDate: new Date(body.Data).toISOString().substring(0, 10) || '',
            status: body.Estado || '',
            document: body.Documento || '',
            documentType: body.TipoDoc || '',
            entity: body.Entidade || '',
            Id: body.Id || '',
            articles: (function () {
                return body.LinhasInternos.map((line: any) => {
                    return {
                        id: line.Id || 0,
                        article: line.Artigo || '',
                        description: line.Descricao || '',
                        quantity: body.TipoDoc == 'RI' ? line.Quantidade * -1 : line.Quantidade || 0,
                        unit: line.Unidade || '',
                        deliveryDate: new Date(line.DataEntrega).toLocaleDateString() || '',
                        project: line.Obra.Codigo || line.ObraId
                    };
                });
            })(),
        };
    };

    return {
        parseSocToPrimaveraWorkCenter,
        parsePrimaveraToSocWorkCenter,
        parseSocToPrimaveraArticle,
        parsePrimaveraToSocArticle,
        parsePrimaveraInternalDocumentToSoc,
    };
}
