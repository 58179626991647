<template>
    <v-row
        id="events-search"
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
        <v-col
            cols="12"
            class="tw-flex tw-flex-wrap tw-gap-2">
            <v-text-field
                class="tw-grow max-sm:tw-w-full"
                clearable
                @update:model-value="onSearch"
                placeholder="Pesquisar"></v-text-field>
            <v-btn
                size="large"
                density="comfortable"
                class="text-gray tw-border tw-border-gray"
                icon="mdi-close"
                @click="onDeleteReservations"></v-btn>
            <!-- <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
        </v-col>
    </v-row>
    <v-row>
        <v-data-table-server
            :headers="headers"
            :hover="true"
            :items="reservations"
            :items-length="total"
            v-model:page="page"
            v-model:items-per-page="pageSize"
            show-select
            v-model="selectedItems"
            item-value="IdReserva">
            <template v-slot:item.DataNecessidade="{ item }">
                <span>{{ formatDate(item.DataNecessidade) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="tw-flex tw-gap-1">
                    <v-btn
                        @click.stop="onActionClick(item)"
                        color="red"
                        size="x-small"
                        icon>
                        <v-icon class="tw-text-xl tw-text-white">mdi-close</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table-server>
    </v-row>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { getReservations, deleteReservation, deleteReservations } from '@/api/reservationsManager';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { useRouter, useRoute } from 'vue-router';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import _, { filter, initial } from 'lodash';
    import { ref, watch } from 'vue';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const reservations = ref([]);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref<number>(1);
    const total = ref<number>(0);
    const pageSize = ref<number>(10);
    const selectedItems = ref([]);

    const filterByIds = ref<any>(null);

    const headers = ref([
        { title: 'Artigo', value: 'Artigo', sortable: true },
        { title: 'Descricao', value: 'ArtigoDesc', sortable: true },
        { title: 'Lote', value: 'Lote', sortable: true },
        { title: 'Armazém', value: 'Armazem', sortable: true },
        { title: 'Localização', value: 'Localizacao', sortable: true },
        { title: 'Qtd. Reservada', value: 'QuantReserv', sortable: true, align: 'end' },
        { title: 'Unidade', value: 'Unidade', sortable: true },
        { title: 'Origem', value: 'Origem', sortable: true },
        { title: 'Destino', value: 'Destino', sortable: true },
        { title: 'Entidade', value: 'Entidade', sortable: true },
        { title: 'Data', value: 'DataNecessidade', sortable: true },
        { title: 'Estado', value: 'Estado', sortable: true },
        { sortable: false, key: 'actions' },
    ]);

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    const init = async () => {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            await getReservations('reservations', _page, _pageSize, search.value, filterByIds.value)
                .then((response) => {
                    reservations.value = response.data.data;
                    total.value = response.data.total;
                    filterByIds.value = reservations.value.map((item) => item.Id);
                })
                .finally(async () => {
                    initialQuery.value = false;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar reservas.',
            });
        }
    };

    init();

    // On search
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    // On delete
    async function onDeleteReservations() {
        if (selectedItems.value.length == 0) {
            $alert.showAlert({
                type: 'warning',
                text: 'Selecione pelo menos uma reserva para anular.',
            });
            return;
        }
        const result = await confirm.value.open('Confirmação', `Tem a certeza que deseja anular as reservas selecionadas?`, 'Sim', 'Não');
        if (!result) return;

        showLoader();

        const ids = selectedItems.value;

        try {
            await deleteReservations(ids)
                .then(() => {
                    $alert.showAlert({
                        type: 'success',
                        text: 'Reservas anuladas com sucesso.',
                    });
                    init();
                })
                .finally(() => {
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao anular reservas.',
            });
        }
    }

    const onActionClick = async (item: any) => {
        const result = await confirm.value.open('Confirmação', `Tem a certeza que deseja anular a reserva?`, 'Sim', 'Não');
        if (!result) return;

        showLoader();
        try {
            await deleteReservation(item.IdReserva)
                .then(() => {
                    $alert.showAlert({
                        type: 'success',
                        text: 'Reserva anulada com sucesso.',
                    });
                    init();
                })
                .finally(() => {
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao anular reserva.',
            });
        }
    };

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            if (initialQuery.value == false) {
                // search.value = $route.query.search as string ?? '';
                init();
            }
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        console.log('page or pageSize changed');
        console.log(page.value, pageSize.value);
        init();
    });

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
