import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';
import { CalcNeed } from '@/types';

export const API_REF = 'primavera/calc-needs';

export async function getCalcNeeds(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<CalcNeed[]> {
    return axios.get(API_REF, {
        params: {
            page,
            limit,
            search,
            filterByIds
        },
    }
    );
}

export async function getCalcNeed(id: string): AxiosPromise<CalcNeed> {
    return axios.get(`${API_REF}/${id}`);
}

export async function createCalcNeed(calcNeed: CalcNeed): AxiosPromise<CalcNeed> {
    return axios.post(API_REF, calcNeed);
}


export async function updateCalcNeed(id: number, calcNeed: CalcNeed): AxiosPromise<CalcNeed> {
    return axios.put(`${API_REF}/${id}`, calcNeed);
}

export async function deleteCalcNeed(id: number): AxiosPromise<any> {
    return axios.delete(`${API_REF}/${id}`);
}

export async function deleteCalcNeeds(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/delete-many`, data);
}


export async function processCalcNeed(calcNeed: CalcNeed): AxiosPromise<CalcNeed> {
    return axios.post(`${API_REF}/process`, calcNeed);
}

export async function getProcessedCalcNeed(): AxiosPromise<CalcNeed[]> {
    return axios.get(`${API_REF}/processed`);
}
