<template>
    <v-container
        v-if="done"
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('internalDocuments.OSI.internalServiceOrder.title') }}: {{ internalOrderService.document }}</h1>
            </v-col>
        </v-row>
        <v-form
            ref="form"
            @submit.prevent="onSubmit">
            <v-row class="tw-mt-3">
                <v-col
                    cols="12"
                    md="4">
                    <v-select
                        v-model="internalOrderService.serie"
                        :items="series"
                        label="Série"
                        outlined
                        dense
                        clearable
                        :rules="[rules.required]" />
                </v-col>
                <v-col
                    cols="12"
                    md="4">
                    <v-text-field
                        type="date"
                        v-model="internalOrderService.documentDate"
                        label="Data do Documento"
                        outlined
                        dense
                        clearable
                        :rules="[rules.required]" />
                </v-col>
                <v-col
                    cols="12"
                    md="4">
                    <v-select
                        v-model="internalOrderService.entity"
                        :items="clients"
                        item-title="Nome"
                        item-value="Cliente"
                        label="Entidade"
                        outlined
                        dense
                        clearable
                        :rules="[rules.required]" />
                </v-col>
            </v-row>
            <v-divider class="tw-mt-3"></v-divider>
            <v-row class="tw-mt-3">
                <v-col>
                    <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                        <hot-table
                            :readOnly="false"
                            :data="internalOrderService.articles"
                            :licenseKey="license"
                            :contextMenu="true"
                            :columns="columns"
                            :rowHeaders="false"
                            :stretchH="'all'"
                            :width="'100%'"
                            :colHeaders="tableHeders"
                            :afterChange="afterChange"
                            :afterLoadData="afterLoadData"
                            :beforeKeyDown="handleBeforeKeyDown"
                            ref="hotTableComponent"></hot-table>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="tw-flex tw-items-end tw-justify-end">
                    <v-btn
                        color="primary"
                        type="submit"
                        >{{ $t('shared.save') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import useRules from '@/composables/rules';
    import { updateInternalDocument } from '@/api/internalDocuments';
    import { getInternalDocument } from '@/api/internalDocuments';
    import { getInternalSeriesByDocType } from '@/api/internalSeries';
    import { getAllProjects } from '@/api/projects';
    import { getAllClients } from '@/api/clients';
    import { getArticles } from '@/api/articles';
    import usePrimavera from '@/composables/primaveraParser';
    import moment from 'moment'; // Import moment for date parsing

    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';

    registerAllModules();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);
    const hotTableComponent = ref(null);

    const { parsePrimaveraInternalDocumentToSoc } = usePrimavera();
    const rules = useRules();

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();

    const internalOrderService = ref(null) as any;
    const series = ref([]);
    const projects = ref([]);
    const clients = ref([]);
    const articles = ref([]);

    const filteredProjects = ref([]);

    const done = ref(false);
    const form = ref(null);

    const tableHeders = ref(['Artigo', 'Descrição', 'Quantidade', 'Data Entrega', 'Projeto']);

    const createDropdownSource = (array: any, key: any) => (done.value ? array.value.map((item: any) => item[key]) : []);

    const validateAllCells = () => {
        if (hotTableComponent.value && hotTableComponent.value.hotInstance) {
            hotTableComponent.value.hotInstance.validateCells();
        }
    };

    const columns = computed(() => {
        return [
            {
                data: 'article',
                type: 'dropdown',
                source: createDropdownSource(articles, 'Artigo'),
                validator: function (value, callback) {
                    // Check if the value is empty (required field)
                    callback(!!value); // Returns true if value is not empty
                },
                allowInvalid: false, // Prevent saving invalid data
            },
            {
                data: 'description',
                type: 'text',
                readOnly: true,
            },
            {
                data: 'quantity',
                type: 'numeric',
                format: '0,0.00',
                validator: function (value, callback) {
                    // Ensure quantity is not null and greater than 0
                    callback(value != null && value > 0);
                },
                allowInvalid: false,
            },
            {
                data: 'deliveryDate',
                type: 'date',
                dateFormat: 'DD/MM/YYYY',
                validator: function (value, callback) {
                    // Ensure a valid date is selected
                    const isValidDate = moment(value, 'DD/MM/YYYY', true).isValid();
                    callback(isValidDate);
                },
            },
            {
                data: 'project',
                type: 'dropdown',
                source: createDropdownSource(filteredProjects, 'Codigo'),
                validator: function (value, callback) {
                    // Ensure a project is selected
                    callback(projects.value.some((project: any) => project.Codigo === value));
                },
            },
        ];
    });

    const init = async () => {
        showLoader();
        try {
            const [internalDocumentResponse, seriesResponse, projectsResponse, clientsResponse] = await Promise.all([
                getInternalDocument($route.params.id as string),
                getInternalSeriesByDocType('OSI'),
                getAllProjects(null),
                getAllClients(),
            ]);
            internalOrderService.value = parsePrimaveraInternalDocumentToSoc(internalDocumentResponse.data);
            series.value = seriesResponse.data.map((item: any) => item.Serie);
            projects.value = projectsResponse.data as any;
            filteredProjects.value = projects.value;
            //clients are the entities
            clients.value = clientsResponse.data as any;

            done.value = true;
            console.log(internalOrderService.value);
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar a requisição interna',
            });
        } finally {
            hideLoader();
        }
    };

    const onSubmit = async () => {
        console.log(internalOrderService.value);
        if (!form.value) return;
        const { valid } = await form.value.validate();
        if (!valid) return;
        try {
            const hotInstance = hotTableComponent.value.hotInstance; // Access Handsontable instance via ref

            // Trigger Handsontable validation
            hotInstance.validateCells((valid) => {
                if (valid) {
                    // If validation passes, proceed with form submission
                    showLoader();

                    const id = $route.params.id as string;

                    // Set project id based on the selected project in each article
                    internalOrderService.value.articles.forEach((article: any) => {
                        const project = projects.value.find((project: any) => project.Codigo === article.project);
                        article.projectId = project?.ID;
                    });

                    internalOrderService.value.articles.forEach((article: any) => {
                        article.deliveryDate = moment(article.deliveryDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    });

                    console.log(internalOrderService.value);

                    updateInternalDocument(id, internalOrderService.value)
                        .then(() => {
                            $alert.showAlert({
                                type: 'success',
                                text: 'Ordem de Serviço Interna atualizada com sucesso.',
                            });
                        })
                        .catch((error) => {
                            $alert.showAlert({
                                type: 'error',
                                text: 'Erro ao atualizar Ordem de Serviço Interna, tente novamente.',
                            });
                        })
                        .finally(() => {
                            hideLoader();
                            init();
                        });
                } else {
                    // If validation fails, show an error message
                    $alert.showAlert({
                        type: 'error',
                        text: 'Preencha todos os campos obrigatórios corretamente antes de atualizar.',
                    });
                }
            });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao atualizar a Ordem de Serviço Interna, tente novamente.',
            });
        }
    };

    const handleBeforeKeyDown = async (event) => {
        const hotInstance = hotTableComponent.value.hotInstance;
        const activeEditor = hotInstance.getActiveEditor();
        const selectedRange = hotInstance.getSelectedRange();
        const activeColumn = selectedRange?.[0]?.from?.col;

        const isPrintableCharacter = event.key.length === 1 && event.key.match(/^[\w\d\s\p{L}\p{N}]$/u);

        if (activeColumn === 0 && isPrintableCharacter) {
            const currentValue = activeEditor?.TEXTAREA?.value || '';
            const newValue = currentValue + event.key;

            if (newValue.length >= 3) {
                // Fetch updated articles based on newValue (user input)
                showLoader();
                await searchArticles(newValue);
                hideLoader();

                // Update the dropdown source dynamically
                const dropdownEditor = hotInstance.getActiveEditor();
                dropdownEditor.cellProperties.source = articles.value.map((article) => article.Artigo);
                setTimeout(() => dropdownEditor.open(), 0);
            }
        }
        if (activeColumn === 4 && isPrintableCharacter) {
            const currentValue = activeEditor?.TEXTAREA?.value || '';
            const newValue = currentValue + event.key;

            if (newValue.length >= 3) {
                // Fetch updated projects based on newValue (user input)
                showLoader();
                await searchProjects(newValue);
                hideLoader();

                // Update the dropdown source dynamically
                const dropdownEditor = hotInstance.getActiveEditor();
                dropdownEditor.cellProperties.source = filteredProjects.value.map((project) => project.Codigo);
                setTimeout(() => dropdownEditor.open(), 0);
            }
        }
    };

    const afterChange = (changes: any, source: any) => {
        if (source === 'edit' || source === 'CopyPaste.paste') {
            changes.forEach(async ([row, prop, oldValue, newValue]) => {
                if (prop === 'article' && oldValue !== newValue) {
                    const article = articles.value.find((a) => a.Artigo === newValue);
                    if (article) {
                        hotTableComponent.value.hotInstance.setDataAtCell(row, 1, article.Descricao);
                    }
                }
            });
            filteredProjects.value = projects.value;
        }
        validateAllCells();
    };

    const afterLoadData = (sourceData, initialLoad, source) => {
        validateAllCells();
    };

    const searchArticles = async (article: string) => {
        try {
            const response = await getArticles(null, null, article, null);
            articles.value = response.data.data;
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    const searchProjects = async (project: string) => {
        try {
            const response = await getAllProjects(project);
            filteredProjects.value = response.data;
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    init();
</script>

<style scoped>
    .hot-table-container {
        height: 300px;
    }
</style>
