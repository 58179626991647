import _ from 'lodash';

export const useChunk = (array = [], size: any) => {
    //receives an array and a size
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
};
