import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';
import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import qs from 'qs';

export const API_REF = 'primavera/daily-works';

export async function getDailyWorks(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<any> {
    return axios.get(`${API_REF}`, {
        params: {
            page,
            limit,
            search,
            filterByIds,
        },
    });
}

export async function getDailyWork(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

export async function createDailyWorks(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/create-many`, data);
}

export async function updateDailyWork(id: string, data: any): AxiosPromise<any> {
    return axios.put(`${API_REF}/${id}`, data);
}

export async function deleteDailyWork(id: string): AxiosPromise<any> {
    return axios.delete(`${API_REF}/${id}`);
}

export async function deleteDailyWorks(ids: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/delete-many`, ids);
}
