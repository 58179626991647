import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/production-orders';

export async function createProductionOrder(productionOrder: any): AxiosPromise<any> {
    return axios.post(API_REF, productionOrder);
}

export async function getProductionOrders(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<any[]> {
    return axios.get(API_REF, {
        params: {
            page,
            limit,
            search,
            filterByIds
        },
    });
}

export async function getProductionOrder(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

export async function updateProductionOrder(id: string, productionOrder: any): AxiosPromise<any> {
    return axios.put(`${API_REF}/${id}`, productionOrder);
}

export async function deleteProductionOrder(id: string): AxiosPromise<any> {
    return axios.delete(`${API_REF}/${id}`);
}

export async function deleteProductionOrders(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/delete-many`, data);
}

// vai buscar as operações de todas as ordens de fabrico
export async function getProductionOrdersOperations(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<any[]> {
    return axios.get(`${API_REF}/operations`, {
        params: {
            page,
            limit,
            search,
            filterByIds,
        },
    });
}

// vai buscar a operação de uma ordem de fabrico
export async function getProductionOrderOperation(operationId: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/operations/direct/${operationId}`);
}

// altera o estado de uma ordem de fabrico
export async function changeProductionOrderStatus(id: string, status: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/${id}/status`, { status });
}

// altera o estado de uma operação de uma ordem de fabrico
export async function changeProductionOrderOperationStatus(id: string, operationId: string, status: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/${id}/operations/${operationId}/status`, { status });
}

// vai buscar os documentos de origem de uma ordem de fabrico
export async function getProductionOrderDocuments(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}/documents`);
}

// gera as listas de corte
export async function generateCuttingLists(body: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/cutting-lists`, body);
}

// vai buscar as linhas de stock para um documento de stock
export async function getStockLines(document: any, productionOrderId: any, operation: any, quantity: any): AxiosPromise<any> {
    return axios.get(`${API_REF}/stock-lines`, { params: { document, productionOrderId, operation, quantity } });
}
