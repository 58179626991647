<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Consumo de Componentes</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.warehouse') }}
                </v-label>
                <div>
                    <v-select
                        :items="warehouses"
                        item-title="Descricao"
                        v-model="workCenter.componentWarehouse"
                        @update:modelValue="workCenter.componentWarehouseLocation = ''"
                        item-value="Armazem">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.location') }}
                </v-label>
                <div>
                    <v-select
                        :items="warehouseLocations.filter((item) => item.Armazem === workCenter.componentWarehouse)"
                        item-title="Descricao"
                        v-model="workCenter.componentWarehouseLocation"
                        item-value="Localizacao">
                    </v-select>
                </div>
            </v-col>
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Entrada de Produto Fabricado</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.warehouse') }}
                </v-label>
                <div>
                    <v-select
                        :items="warehouses"
                        item-title="Descricao"
                        v-model="workCenter.productEntranceWarehouse"
                        @update:modelValue="workCenter.productEntranceWarehouseLocation = ''"
                        item-value="Armazem">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.location') }}
                </v-label>
                <div>
                    <v-select
                        :items="warehouseLocations.filter((item) => item.Armazem === workCenter.productEntranceWarehouse)"
                        item-title="Descricao"
                        v-model="workCenter.productEntranceWarehouseLocation"
                        item-value="Localizacao">
                    </v-select>
                </div>
            </v-col>
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Rejeitado</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.warehouse') }}
                </v-label>
                <div>
                    <v-select
                        :items="warehouses"
                        item-title="Descricao"
                        v-model="workCenter.rejectedProductWarehouse"
                        @update:modelValue="workCenter.rejectedProductWarehouseLocation = ''"
                        item-value="Armazem">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.location') }}
                </v-label>
                <div>
                    <v-select
                        :items="warehouseLocations.filter((item) => item.Armazem === workCenter.rejectedProductWarehouse)"
                        item-title="Descricao"
                        v-model="workCenter.rejectedProductWarehouseLocation"
                        item-value="Localizacao">
                    </v-select>
                </div>
            </v-col>
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Subcontratação - Transferência de Stock</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.warehouse') }}
                </v-label>
                <div>
                    <v-select
                        :items="warehouses"
                        item-title="Descricao"
                        v-model="workCenter.warehouse"
                        @update:modelValue="workCenter.location = ''"
                        item-value="Armazem">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.location') }}
                </v-label>
                <div>
                    <v-select
                        :items="warehouseLocations.filter((item) => item.Armazem === workCenter.warehouse)"
                        item-title="Descricao"
                        v-model="workCenter.location"
                        item-value="Localizacao">
                    </v-select>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { Ref, ref, watch } from 'vue';
    import { WorkCenter, WorkCenterSOC } from '@/types';
    import useRules from '@/composables/rules';
    import { VForm } from 'vuetify/lib/components/index.mjs';
import { update } from 'lodash';

    const form = ref<VForm>();

    const rules = useRules();
    const props = defineProps(['workCenter', 'warehouses', 'warehouseLocations']);

    const workCenter = ref(props.workCenter);
    console.log(workCenter.value);
    const warehouses = ref(props.warehouses);
    const warehouseLocations = ref(props.warehouseLocations);

    const onSubmit = () => {
        console.log('onSubmit');
    };
</script>

<style scoped></style>
