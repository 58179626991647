import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/workers';



export async function getWorkers(): AxiosPromise<any> {
    return axios.get(`${API_REF}`);
}

export async function getWorker(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}
