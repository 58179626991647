<template>
    <v-row class="tw-mt-3">
        <v-data-table
            :headers="headers"
            :items="lines"
            v-model:page="page"
            v-model:items-per-page="pageSize"
            item-value="Id">
            <template v-slot:item.Quantidade="{ item }">
                <span>{{ Math.abs(item?.Quantidade) }}</span>
            </template>
            <template v-slot:item.PrecUnit="{ item }">
                <span>{{ Math.abs(item.PrecUnit) }}</span>
            </template>
        </v-data-table>
    </v-row>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';

    const page = ref<number>(1);
    const pageSize = ref<number>(10);

    const props = defineProps(['documents']);
    const lines = props.documents.flatMap((document: any) =>
        document.LinhasInternos.map((linha : any) => ({
            ...linha,
            TipoDoc: document.TipoDoc,
        })),
    );
    console.log('Lines', lines.value);

    const headers = ref([
        { title: 'Tipo', key: 'TipoDoc' },
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Armazém', key: 'Armazem' },
        { title: 'Localização', key: 'Localizacao' },
        { title: 'Lote', key: 'Lote' },
        { title: 'Unidade', key: 'Unidade' },
        { title: 'Quantidades', key: 'Quantidade', align: 'end' },
        { title: 'Valor', key: 'PrecUnit', align: 'end' },
    ]);
</script>

<style scoped></style>
