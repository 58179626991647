<template>
    <v-dialog
        v-model="dialog"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="close">
        <v-card :color="options.color">
            <v-toolbar
                dark
                :color="options.color"
                dense
                flat>
                <v-toolbar-title class="text-body-2 font-weight-bold tw-text-secondary">
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text
                v-show="!!message"
                class="pa-4 tw-bg-white tw-text-black tw-text-sm"
                v-html="message"></v-card-text>
            <v-card-actions class="pt-3 tw-bg-white">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!options.noconfirm"
                    color="grey"
                    class="body-2 font-weight-bold"
                    @click="cancel"
                    >{{ cancelButton }}</v-btn
                >
                <v-btn
                    color="primary"
                    class="body-2 font-weight-bold"
                    outlined
                    @click="agree"
                    >{{ confirmButton }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { ref, defineExpose } from 'vue';

    const dialog = ref(false);
    const resolve = ref<((value: boolean | PromiseLike<boolean>) => void) | null>(null);
    const reject = ref<((reason?: any) => void) | null>(null);
    const message = ref<string | null>(null);
    const title = ref<string | null>(null);
    const confirmButton = ref<string | null>(null);
    const cancelButton = ref<string | null>(null);
    const options = ref({
        color: 'gray-300',
        width: 400,
        zIndex: 200,
        noconfirm: false,
    });

    function open(newTitle: string, newMessage: string, confirmBtn: string, cancelBtn: string, newOptions?: Partial<typeof options.value>) {
        dialog.value = true;
        title.value = newTitle;
        message.value = newMessage;
        confirmButton.value = confirmBtn;
        cancelButton.value = cancelBtn;
        if (newOptions) {
            options.value = { ...options.value, ...newOptions };
        }
        return new Promise<boolean>((res, rej) => {
            resolve.value = res;
            reject.value = rej;
        });
    }

    function agree() {
        if (resolve.value) {
            resolve.value(true);
        }
        dialog.value = false;
    }

    function cancel() {
        if (resolve.value) {
            resolve.value(false);
        }
        dialog.value = false;
    }

    function close() {
        if (reject.value) {
            reject.value();
        }
        dialog.value = false;
    }

    defineExpose({ open });
</script>
