<template>
    <v-row
        id="events-search"
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
        <v-col
            cols="12"
            class="tw-flex tw-flex-wrap tw-gap-2">
            <v-text-field
                class="tw-grow max-sm:tw-w-full"
                clearable
                @update:model-value="onSearch"
                placeholder="Pesquisar"></v-text-field>
            <v-btn
                size="large"
                density="comfortable"
                class="text-gray tw-border tw-border-gray"
                icon="mdi-check"
                @click="onAutomaticReservation"></v-btn>
            <!-- <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
        </v-col>
    </v-row>
    <v-row>
        <v-data-table-server
            :headers="headers"
            :hover="true"
            :items="needs"
            :items-length="total"
            v-model:page="page"
            v-model:items-per-page="pageSize"
            show-select
            v-model="selectedItems"
            item-value="IdLinha">
            <template v-slot:header.data-table-select> </template>
            <template v-slot:item.data-table-select="{ isSelected, select, item }">
                <v-checkbox
                    class="checkbox"
                    :input-value="selectedItems.includes(item)"
                    :disabled="item.Stock <= 0"
                    @change="toggleSelection(item)" />
            </template>
            <template v-slot:item.DataEntrega="{ item }">
                <span>{{ formatDate(item.DataEntrega) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="tw-flex tw-gap-1">
                    <v-btn
                        @click.prevent="onActionClick(item)"
                        color="green"
                        size="x-small"
                        icon>
                        <v-icon class="tw-text-xl tw-text-white">mdi-check</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table-server>
    </v-row>
    <!-- Pop-up com a Reserva de Quantidades -->
    <v-dialog
        v-model="dialog"
        max-width="1400">
        <v-card>
            <v-card-title>
                <v-container
                    ><div class="tw-flex tw-items-center tw-gap-x-4">
                        <v-icon class="tw-rounded-md tw-bg-primary tw-p-5 tw-text-white">mdi-check</v-icon>
                        <span class="text-h5 tw-font-bold">Reserva de Quantidades</span>
                    </div></v-container
                ></v-card-title
            >
            <v-card-text>
                <QuantitiesReservation
                    :need="need"
                    :quantitiesReservation="quantitiesReservation"
                    @update:close="closeDialog"
                    @update:success="init"
            /></v-card-text>
        </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { getReservations, getQuantitiesReservation, suggestAutomaticReservations } from '@/api/reservationsManager';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { useRouter, useRoute } from 'vue-router';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import _, { filter, initial } from 'lodash';
    import { ref, watch } from 'vue';
    import QuantitiesReservation from './QuantitiesReservation.vue';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const needs = ref([]);
    const need = ref({});
    const quantitiesReservation = ref([]);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref<number>(1);
    const total = ref<number>(0);
    const pageSize = ref<number>(10);
    const selectedItems = ref([]);

    const filterByIds = ref<any>(null);

    const headers = ref([
        { title: 'Destino', value: 'Destino', sortable: true },
        { title: 'Documento', value: 'Documento', sortable: true },
        { title: 'Entidade', value: 'Entidade', sortable: true },
        { title: 'Data', value: 'DataEntrega', sortable: true },
        { title: 'Artigo', value: 'Artigo', sortable: true },
        { title: 'Descricao', value: 'ArtigoDesc', sortable: true },
        { title: 'Qtd. Original', value: 'QuantOrig', sortable: true, align: 'end' },
        { title: 'Qtd. Pendente', value: 'QuantPend', sortable: true, align: 'end' },
        { title: 'Qtd. Reservada', value: 'QuantReserv', sortable: true, align: 'end' },
        { title: 'Unidade', value: 'Unidade', sortable: true },
        { title: 'Armazém', value: 'Armazem', sortable: true },
        { title: 'Localização', value: 'Localizacao', sortable: true },
        { title: 'Lote', value: 'Lote', sortable: true },
        { sortable: false, key: 'actions' },
    ]);

    const dialog = ref(false);

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    const init = async () => {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            await getReservations('needs', _page, _pageSize, search.value, filterByIds.value)
                .then((response) => {
                    needs.value = response.data.data;
                    total.value = response.data.total;
                    filterByIds.value = needs.value.map((item) => item.Id);
                })
                .finally(async () => {
                    initialQuery.value = false;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar necessidades.',
            });
        }
    };

    init();

    const onActionClick = async (item: any) => {
        try {
            showLoader();
            need.value = item;
            await getQuantitiesReservation(item.IdDocumento.toLowerCase(), item.Artigo)
                .then((response) => {
                    quantitiesReservation.value = response.data;
                    dialog.value = true;
                })
                .finally(() => {
                    dialog.value = true;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar a reserva de quantidades.',
            });
        }
    };

    const onAutomaticReservation = async () => {
        console.log(selectedItems.value);
        if (selectedItems.value.length == 0) {
            $alert.showAlert({
                type: 'warning',
                text: 'Selecione pelo menos uma reserva automática para confirmar.',
            });
            return;
        }
        const result = await confirm.value.open('Confirmação', `Tem a certeza que deseja confirmar as reservas automáticas selecionadas?`, 'Sim', 'Não');
        if (!result) return;

        showLoader();

        try {
            const data = selectedItems.value;
            await suggestAutomaticReservations(data)
                .then(() => {
                    $alert.showAlert({
                        type: 'success',
                        text: 'Reservas automáticas confirmadas com sucesso.',
                    });
                    init();
                })
                .finally(() => {
                    hideLoader();
                });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text:
                    (error?.response?.data?.error?.message !== undefined || error?.response?.data?.error?.message !== null) && error?.response?.data?.error?.status !== 500
                        ? error.response.data.error.message
                        : 'Erro ao confirmar as reservas automáticas. Por favor, tente novamente.',
            });
        }
    };

    // On search
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            if (initialQuery.value == false) {
                // search.value = $route.query.search as string ?? '';
                init();
            }
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        console.log('page or pageSize changed');
        console.log(page.value, pageSize.value);
        init();
    });

    const toggleSelection = (item: any) => {
        console.log(item);
        const index = selectedItems.value.indexOf(item);
        if (index === -1 && item.QuantPend > 0) {
            item.QuantReserv = item.QuantPend;
            selectedItems.value.push(item);
        } else {
            item.QuantReserv = 0;
            selectedItems.value.splice(index, 1);
        }
    };

    const closeDialog = () => {
        dialog.value = false;
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
