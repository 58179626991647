<template>
    <v-navigation-drawer
        class="tw-bg-gray-100"
        width="300"
        :v-model="true"
        rail-width="80"
        :rail="rail"
        permanent
        @click="rail = false">
        <v-list-item nav>
            <template
                v-if="!rail"
                v-slot:>
                <v-img
                    class="tw-m-auto tw-block tw-cursor-pointer tw-py-12"
                    @click="$router.push({ name: 'Panel' })"
                    width="120"
                    src="@/assets/images/logo-stefa.svg"
                    alt="Stefa Logo"></v-img>
            </template>
            <template v-slot:append>
                <v-btn
                    class="tw-m-4 tw-mx-auto tw-flex tw-w-[61px] tw-text-xl tw-text-primary"
                    :icon="rail ? 'mdi-menu' : 'mdi-chevron-left'"
                    variant="text"
                    @click.stop="rail = !rail"></v-btn>
            </template>
        </v-list-item>
        <!-- <v-divider></v-divider> -->
        <v-list
            :class="{ 'custom-sidebar': rail, 'tw-px-6': !rail }"
            class="v-list-mb tw-items-center tw-justify-center"
            density="compact"
            nav>
            <v-list-item
                prepend-icon="mdi-monitor-dashboard"
                color="primary"
                :title="$t('panel.title')"
                :to="{ name: 'Panel' }"></v-list-item>
            <v-list-item
                prepend-icon="mdi-view-dashboard"
                color="primary"
                :title="$t('workCenters.title')"
                :to="{ name: 'WorkCenters' }"></v-list-item>
            <v-list-group
                prepend-icon="mdi-package-variant-closed"
                value="Articles">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('articles.title')"></v-list-item>
                </template>
                <v-list-item
                    prepend-icon="mdi-format-list-bulleted-square"
                    color="primary"
                    :title="$t('articles.listArticles')"
                    :to="{ name: 'Articles' }"></v-list-item>
                <v-list-item
                    prepend-icon="mdi-file-import"
                    color="primary"
                    :title="$t('articles.loadArticles')"
                    :to="{ name: 'LoadArticles' }"></v-list-item>
            </v-list-group>
            <v-list-group
                prepend-icon="mdi-file-excel"
                value="TechnicalSheets">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('techincalSheets.nav')"></v-list-item>
                </template>
                <v-list-item
                    prepend-icon="mdi-format-list-bulleted-square"
                    color="primary"
                    :title="$t('techincalSheets.listTechnicalSheets')"
                    :to="{ name: 'TechnicalSheets' }"></v-list-item>
                <v-list-item
                    v-if="authStore.user?.role?.name == 'Project' || authStore.user?.role?.name == 'Authenticated'"
                    prepend-icon="mdi-file-import"
                    color="primary"
                    :title="$t('techincalSheets.loadInventorTechnicalSheets')"
                    :to="{ name: 'LoadInventorTechnicalSheet' }"></v-list-item>
            </v-list-group>
            <v-list-group
                prepend-icon="mdi-file-document-multiple"
                value="InternalDocuments">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('internalDocuments.nav')"></v-list-item>
                </template>
                <v-list-item
                    prepend-icon="mdi-file-document"
                    color="primary"
                    :title="$t('internalDocuments.RI.title')"
                    :to="{ name: 'InternalRequests' }"></v-list-item>
                <v-list-item
                    prepend-icon="mdi-file-document"
                    color="primary"
                    :title="$t('internalDocuments.OSI.title')"
                    :to="{ name: 'InternalOrderServices' }"></v-list-item>
            </v-list-group>
            <v-list-item
                prepend-icon="mdi-calculator"
                color="primary"
                :title="$t('calcNeeds.nav')"
                :to="{ name: 'CalcNeeds' }"></v-list-item>
            <v-list-item
                prepend-icon="mdi-clipboard-check-multiple"
                color="primary"
                :title="$t('reservationsManager.nav')"
                :to="{ name: 'RerservationsManager' }"></v-list-item>
            <!-- <v-list-group
                prepend-icon="mdi-clipboard-check-multiple"
                value="ReservationsManager">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('reservationsManager.nav')"></v-list-item>
                </template>
                <v-list-item
                    prepend-icon="mdi-format-list-checks"
                    color="primary"
                    :title="$t('reservationsManager.reservations.title')"
                    :to="{ name: 'Reservations' }"></v-list-item>
                <v-list-item
                    prepend-icon="mdi-format-list-checks"
                    color="primary"
                    :title="$t('reservationsManager.needs.title')"
                    :to="{ name: 'Needs' }"></v-list-item>
            </v-list-group> -->
            <v-list-group
                prepend-icon="mdi-order-bool-descending-variant"
                value="ProductionOrders">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('productionOrders.nav')"></v-list-item>
                </template>
                <v-list-item
                    prepend-icon="mdi-format-list-bulleted-square"
                    color="primary"
                    :title="$t('productionOrders.listProductionOrders')"
                    :to="{ name: 'ProductionOrders' }"></v-list-item>
                <v-list-item
                    v-if="authStore.user?.role?.name == 'Authenticated'"
                    prepend-icon="mdi-format-list-bulleted"
                    color="primary"
                    :title="$t('productionOrders.operations.operationsList')"
                    :to="{ name: 'Operations' }"></v-list-item>
                <v-list-item
                    v-if="authStore.user?.role?.name == 'Authenticated'"
                    prepend-icon="mdi-format-list-bulleted-triangle"
                    color="primary"
                    :title="$t('productionOrders.cuttings.title')"
                    :to="{ name: 'Cuttings' }"></v-list-item>
                <v-list-item
                    v-if="authStore.user?.role?.name == 'Authenticated'"
                    prepend-icon="mdi-touch-text-outline"
                    color="primary"
                    :title="$t('productionOrders.controlPanel')"
                    :to="{ name: 'ControlPanel' }"></v-list-item>
            </v-list-group>
            <v-list-item
                prepend-icon="mdi-account-hard-hat"
                color="primary"
                :title="$t('dailyWork.nav')"
                :to="{ name: 'DailyWorks' }"></v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list
                class="tw-bg-gray-100 tw-px-3 tw-py-6 tw-text-left tw-text-base"
                bg-color="tw-text-secondary-light">
                <v-list-item>
                    <div class="tw-flex tw-items-center tw-justify-between">
                        <v-avatar :class="{ 'tw-size-6': rail, 'tw-size-10': !rail }">
                            <ioImg
                                :media="me?.picture"
                                class="tw-rounded-full tw-border tw-border-gray-300"
                                format="thumbnail"
                                aspect-ratio="1" />
                        </v-avatar>
                        <div v-if="!rail">
                            <p>{{ me?.username }}</p>
                            <p class="tw-text-xs">{{ me?.email }}</p>
                        </div>
                        <v-list-item-icon
                            v-if="!rail"
                            class="!tw-text-primary_lighter_2 tw-cursor-pointer"
                            @click="logout()">
                            <v-icon> mdi-logout </v-icon>
                        </v-list-item-icon>
                    </div>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { ref, watch, onBeforeUnmount, computed } from 'vue';
    import { useAppStore } from '../store/app';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import { useDisplay } from 'vuetify/lib/framework.mjs';
    import { useMedia } from '@/composables/useMedia';
    import ioImg from '../components/ioImg.vue';
    import { colord } from 'colord';

    const { lgAndUp } = useDisplay();

    const appStore = useAppStore();
    const authStore = useAuthStore();
    const $router = useRouter();

    const rail = ref(false);

    const me = ref(authStore.user);

    const picture = computed(() => {
        console.log(me.value.picture);
        return useMedia(me.value.picture, 'user').small;
    });

    const isMobile = computed(() => {
        return !lgAndUp.value;
    });

    watch(isMobile, (value) => {
        if (value) (rail.value = true), appStore.setIsSidebarOpen(false);
        else (rail.value = false), appStore.setIsSidebarOpen(true);
    });

    onBeforeUnmount(() => {
        appStore.setIsSidebarOpen(false);
    });

    async function logout() {
        authStore.$reset();
        $router.push({ name: 'Login' });
    }
</script>

<style>
    .logo img,
    .v-navigation-drawer__content img {
        display: block !important;
    }

    .v-navigation-drawer .v-list-group__items {
        height: auto !important;
        --indent-padding: 0.3125rem !important;
    }
    .v-navigation-drawer .custom-sidebar .v-list-group__items .v-list-item {
        max-width: 65px;
        padding-right: 9px !important;
        padding-inline-start: calc(3px + var(--indent-padding)) !important;
    }
    .v-navigation-drawer .custom-sidebar {
        padding-left: 21.5px !important;
        max-width: 68px !important;
        /* margin-bottom: 10px !important; */
    }
    .v-navigation-drawer .v-list-mb .v-list-item--nav:not(:only-child) {
        margin-bottom: 14px !important;
    }
    .v-navigation-drawer--left {
        border-right-width: 0px !important;
    }
</style>
