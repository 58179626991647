<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3 tw-px-3">
            <v-col>
                <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                    <hot-table
                        :data="workCenter.operators"
                        :licenseKey="license"
                        :contextMenu="true"
                        :columns="columns"
                        :rowHeaders="false"
                        :stretchH="'all'"
                        :width="'100%'"
                        :colHeaders="tableHeaders"
                        ref="hotTableComponent"></hot-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import _ from 'lodash';
    import { WorkCenter, WorkCenterSOC } from '@/types';
    import useRules from '@/composables/rules';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';
    import Handsontable from 'handsontable';

    const form = ref<VForm>();

    registerAllModules();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);
    const hotTableComponent = ref(null);

    const props = defineProps(['workCenter', 'workers']);

    const workCenter = ref(props.workCenter);
    const workers = ref(props.workers);

    const tableHeaders = ref(['Operador', 'Nome', 'Valor Hora']);

    const createDropdownSource = (array: any, key: any) => (workCenter.value ? array.map((item: any) => item[key]) : []);

    const columns = computed(() => {
        return [
            {
                data: 'IDOperador',
                type: 'dropdown',
                source: createDropdownSource(workers.value, 'Operador'),
                validator: function (value, callback) {
                    if (_.isString(value)) {
                        value = workers.value.find((item: any) => item.Operador === value)?.IDOperador;
                    }
                    // Check if the value is empty (required field), bigger than 0 and less than the last index of the array
                    callback(!!value && value > 0 && value <= workers.value.length);
                },
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    if (_.isInteger(value)) {
                        td.innerHTML = workers.value.find((item: any) => item.IDOperador === value)?.Operador;
                    }
                    if (_.isString(value)) {
                        value = workers.value.find((item: any) => item.Operador === value)?.IDOperador;
                    }
                    workCenter.value.operators[row].IDOperador = value;
                },
            },
            {
                data: '',
                type: 'text',
                readOnly: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.TextRenderer.apply(this, arguments);
                    if (_.isInteger(workCenter.value.operators[row].IDOperador)) {
                        td.innerHTML = workers.value.find((item: any) => item.IDOperador === workCenter.value.operators[row].IDOperador)?.Nome;
                    }
                    if (_.isString(workCenter.value.operators[row].IDOperador)) {
                        td.innerHTML = workers.value.find((item: any) => item.Operador === workCenter.value.operators[row].IDOperador)?.Nome;
                    }
                },
            },
            {
                data: 'ValorHora',
                type: 'numeric',
                format: '0,0.00',
            },
        ];
    });

    const onSubmit = () => {
        console.log('onSubmit');
        console.log(workCenter.value);
    };
</script>

<style scoped>
    .hot-table-container {
        height: 300px;
    }
</style>
