import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/internal-documents';

export async function createInternalDocument(internalDocument: any): AxiosPromise<any> {
    return axios.post(`${API_REF}`, internalDocument);
}

export async function updateInternalDocument(id: string, internalDocument: any): AxiosPromise<any> {
    return axios.put(`${API_REF}/${id}`, internalDocument);
}

export async function getInternalDocumentsByDocType(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null, docType: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/type/${docType}`, {
        params: {
            page,
            limit,
            search,
            filterByIds,
        },
    });
}

export async function getInternalDocument(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

export async function deleteInternalDocuments(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/delete-many`, data);
}

