import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/projects';

export async function getAllProjects(search: string | null): AxiosPromise<any[]> {
    return axios.get(API_REF, {
        params: {
            search,
        },
    }
    );
}

export async function getProjectByCode(docType: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${docType}`);
}

// export async function getWorkCenter(id: number): AxiosPromise<Section> {
//     return axios.get(`${API_REF}/${id}`);
// }
