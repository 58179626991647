<template>
    <div
        v-if="props.articleOperations?.length > 0"
        class="tw-mt-3">
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-between">
                <p class="tw-text-xl tw-font-semibold">Operações</p>
                <v-btn
                    v-if="cutList?.length > 0"
                    color="secondary"
                    prepend-icon="mdi-download"
                    @click="downloadCutList"
                    >Descarregar Lista de Corte</v-btn
                >
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels
                    class="tw-mb-3"
                    elevation="0"
                    color="gray-200"
                    v-for="operation in props.articleOperations">
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <v-row>
                                <v-col class="tw-flex tw-items-end tw-justify-between tw-mr-2">
                                    <p class="tw-text-lg">{{ operation.OperacaoProducao }} - {{ operation.Descricao }} ( {{ operation.Operacao }} )</p>
                                    <v-chip color="secondary" size="default">{{ operation.Componentes?.length }} Componentes</v-chip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-row>
                                <v-col>
                                    <p class="tw-text-l tw-font-semibold">Componentes:</p>
                                </v-col>
                            </v-row>
                            <v-data-table
                                :hover="true"
                                @click:row="handleClick"
                                :items="operation.Componentes"></v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch, computed } from 'vue';
    import { useLoader } from '@/composables/useLoader';
    import { defineProps, defineEmits } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useRoute } from 'vue-router';
    import * as XLSX from 'xlsx';

    const props = defineProps(['article', 'families', 'articleTypes', 'units', 'articleOperations', 'parentArticles']);
    const emit = defineEmits(['drilldown:article']);
    const $alert = useAlert();
    const $route = useRoute();

    const cutList = computed(() => {
        return props.articleOperations?.filter((operation: any) => {
            return operation.Componentes?.some((component: any) => {
                return component.CDU_MedidaCorte && component.CDU_MedidaFinal;
            });
        });
    });

    const onSubmit = () => {
        console.log('submit');
    };

    const init = () => {
        if (props.articleOperations?.length == 0) {
            // show alert
            $alert.showAlert({
                type: 'warning',
                text: 'Não existem operações para este artigo',
            });
        }
    };

    init();

    const handleClick = (event: any, row: any) => {
        console.log('Clicked item: ', row.item);
        emit('drilldown:article', row.item.Componente);
    };

    const downloadCutList = () => {
        if (cutList.value?.length > 0) {
            const data = cutList.value
                .map((operation: any) => {
                    return operation.Componentes.map((component: any) => {
                        return {
                            Artigo: props.article.Artigo,
                            'Descrição Artigo': props.article.Descricao,
                            Operação: operation.Operacao,
                            Descrição: operation.Descricao,
                            Componente: component.Componente,
                            'Descrição Componente': component.DescricaoComponente,
                            'Medida Corte': component.CDU_MedidaCorte,
                            'Medida Final': component.CDU_MedidaFinal,
                        };
                    });
                })
                .flat();

            // download file using XLSX library
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'CutList');
            XLSX.writeFile(wb, 'Lista de Corte.xlsx');

            // show alert
            $alert.showAlert({
                type: 'success',
                text: 'Lista de corte descarregada com sucesso',
            });
        } else {
            // show alert
            $alert.showAlert({
                type: 'warning',
                text: 'Não existem componentes com medidas de corte e final definidas',
            });
        }
    };
</script>

<style scoped>
    /* .v-expansion-panel-title--active .v-expansion-panel-text {
    display: block !important;
}
 */

    .v-expansion-panel-text {
        display: block !important;
    }

    .hot-table-container {
        height: 85vh;
    }
</style>
