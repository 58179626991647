<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Dados Gerais</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.name') }}
                </v-label>
                <div>
                    <v-text-field
                        :rules="[rules.required]"
                        v-model="workCenter.name"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.description') }}
                </v-label>
                <div>
                    <v-text-field
                        :rules="[rules.required]"
                        v-model="workCenter.description"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.section') }}
                </v-label>
                <div>
                    <v-select
                        :items="sections"
                        item-title="Seccao"
                        v-model:model-value="workCenter.section"
                        item-value="IDSeccao">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.nOrder') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.nOrder"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.workCalendar') }}
                </v-label>
                <div>
                    <v-select
                        :items="workCalendars"
                        item-title="CalendarioTrabalho"
                        v-model:model-value="workCenter.workCalendar"
                        item-value="CalendarioTrabalho">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> {{ $t('workCenters.fields.nShifts') }} </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.nShifts"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.capacityFactor') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.capacityFactor"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.efficiency') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.efficiency"></v-text-field>
                </div>
            </v-col>
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Tempos</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.preparationTime') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.preparationTime"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.waitingTime') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.waitingTime"></v-text-field>
                </div>
            </v-col>
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Custos Hora</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="3"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.calculatedMachineCost') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.calculatedMachineCost"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="3"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.reviewedMachineCost') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.reviewedMachineCost"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="3"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.calculatedOperatorCost') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.calculatedOperatorCost"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="3"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.reviewedOperatorCost') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        v-model="workCenter.reviewedOperatorCost"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="9"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('workCenters.fields.obs') }}
                </v-label>
                <div>
                    <v-textarea v-model="workCenter.obs"></v-textarea>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { Ref, ref } from 'vue';
    import { WorkCenter, WorkCenterSOC } from '@/types';
    import useRules from '@/composables/rules';
    import { VForm } from 'vuetify/lib/components/index.mjs';

    const form = ref<VForm>();

    const rules = useRules();
    const props = defineProps(['workCenter', 'sections', 'workCalendars']);

    const workCenter = ref(props.workCenter);
    const sections = ref(props.sections);
    const workCalendars = ref(props.workCalendars);
    console.log(workCenter.value);

    const onSubmit = () => {
        console.log('onSubmit');
    };
</script>

<style scoped></style>
