import { useI18n } from 'vue-i18n';

export default function useRules() {
    const i18n = useI18n();
    return {
        required(value: string | number | boolean) {
            return value !== null && value !== undefined && value !== '' || i18n.t('rules.required');
        },
        email(value: string) {
            // eslint-disable-next-line
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/i;
            return re.test(value) || i18n.t('rules.email');
        },
        min(value: any, min: number) {
            return value.length >= min || i18n.t('rules.min', [min]);
        },
        max(value: any, max: number) {
            return value.length <= max || i18n.t('rules.max', [max]);
        },
        between(value: any, min: number, max: number) {
            return (value.length >= min && value.length <= max) || i18n.t('rules.between', [min, max]);
        },
        comparePassword(value: string, other: string) {
            return value === other || i18n.t('rules.comparePassword');
        },
        numeric(value: any) {
            return !isNaN(parseFloat(value)) || i18n.t('rules.numeric');
        },
        positive(value: any) {
            return parseFloat(value) > 0 || i18n.t('rules.positive');
        },
        nonNegative(value: any) {
            return parseFloat(value) >= 0 || i18n.t('rules.nonNegative');
        },
        afterDate(value: any, date: string) {
            const formattedDate = new Date(date);
            const readableDate = `${formattedDate.toLocaleDateString('pt-PT')} ${formattedDate.toLocaleTimeString('pt-PT', { hour: '2-digit', minute: '2-digit' })}`;

            return new Date(value) > new Date(date) || i18n.t('rules.afterDate', [readableDate]);
        }
    };
}
