<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('articles.articlesImporter') }}</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    prepend-icon="mdi-download"
                    color="secondary"
                    @click="downloadTemplate"
                    >{{ $t('shared.downloadTemplate') }}</v-btn
                >
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-file-input
                    chips
                    accept=".xlsx"
                    @update:modelValue="fileChangeXLSX"
                    label="Artigos"></v-file-input>
            </v-col>
        </v-row>
        <v-row>
            <div class="tw-flex tw-w-full tw-items-end tw-justify-end tw-pr-3">
                <v-btn
                    @click="sendArticles"
                    type="button"
                    :disabled="!articlesToSend || isRequested"
                    color="primary">
                    Importar Artigos
                </v-btn>
            </div>
        </v-row>
        <v-row v-if="articlesToSend" class="tw-px-3">
            <div class="hot-table-container tw-mt-3 tw-w-full tw-overflow-x-auto">
                <hot-table
                    :data="articlesToSend"
                    :licenseKey="license"
                    :rowHeaders="false"
                    :stretchH="'all'"
                    :width="'100%'"
                    :colHeaders="headers"
                    :readOnly="true"></hot-table>
            </div>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { parse } from 'csv-parse/browser/esm/sync';
    import * as XLSX from 'xlsx';
    import { ref, computed } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { loadArticles } from '@/api/articles';
    import { getArticlesUploadTemplate } from '@/api/articlesUploadTemplate';
    import { useLoader } from '@/composables/useLoader';

    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';
    import Handsontable from 'handsontable';

    registerAllModules();
    const headers = ref(['Part Number', 'Família Nova (Primavera)', 'Description', 'UnidadeBase', 'UnidadeCompra', 'FatorConversao', 'Mass', 'PrecoVenda', 'Observacoes']);


    const { showLoader, hideLoader } = useLoader();

    // variables to send to backend
    const articlesToSend = ref<any | null>(null);
    const isRequested = ref(false);

    const $alert = useAlert();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);

    const fileChangeXLSX = async (file: File[]) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as any);
                const workbook = XLSX.read(data, { type: 'array' });

                const articles = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { defval: '' });

                console.log(articles);

                // check if the file has the correct headers
                const headers_ = Object.keys(articles[0]);

                console.log(headers_.length, headers.value.length);


                if (headers.value.every((value, index) => value !== headers_[index])) {
                    $alert.showAlert({
                        type: 'error',
                        text: 'Ficheiro inválido',
                    });
                    return;
                }

                articlesToSend.value = articles;
            } catch (error) {
                $alert.showAlert({
                    type: 'error',
                    text: 'Erro ao importar artigos',
                });
            }
        };
        reader.readAsArrayBuffer(file as any);
    };

    const sendArticles = async () => {
        isRequested.value = true;
        showLoader();

        console.log(articlesToSend.value);
        try {
            await loadArticles(articlesToSend.value);
            $alert.showAlert({
                type: 'success',
                text: 'Artigos importados com sucesso',
            });
        } catch (error) {
            if (error.response.status === 413)
                $alert.showAlert({
                    type: 'error',
                    text: 'Ficheiro demasiado grande',
                });
            else
                $alert.showAlert({
                    type: 'error',
                    text: 'Erro ao importar artigos',
                });
        } finally {
            isRequested.value = false;
            hideLoader();
        }
    };

    const downloadTemplate = async () => {
        console.log('download template');

        try {
            const response = await getArticlesUploadTemplate();

            const data = response.data?.data?.data;

            const sorted = data.map((record: any) => {
                const sortedRecord = {} as any;
                headers.value.forEach((header) => {
                    sortedRecord[header] = record[header];
                });
                return sortedRecord;
            });

            const ws = XLSX.utils.json_to_sheet(sorted);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Artigos');
            XLSX.writeFile(wb, 'ArtigosUpload.xlsx');

            console.log(data);
        } catch (error) {
            console.log(error);

            $alert.showAlert({
                type: 'error',
                text: 'Erro ao descarregar template',
            });
            return;
        }

        console.log('cenas');
    };
</script>

<style scoped>
    .hot-table-container {
        height: 80vh;
    }
</style>
