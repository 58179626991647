<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Registo de Quantidades</h1>
            </v-col>
            <v-col
                cols="12"
                md="8"
                sm="4">
                <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex">
                            <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> No início da picagem: </v-label>
                            <v-checkbox-btn
                                color="primary"
                                v-model="workCenter.registerQuantitiesInInit"></v-checkbox-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="8"
                sm="4">
                <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex">
                            <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Durante a picagem: </v-label>
                            <v-checkbox-btn
                                color="primary"
                                v-model="workCenter.registerQuantitiesDuring"></v-checkbox-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="8"
                sm="4">
                <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex">
                            <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> No fim da picagem: </v-label>
                            <v-checkbox-btn
                                color="primary"
                                v-model="workCenter.registerQuantitiesInEnd"></v-checkbox-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Picagens Automáticas</h1>
            </v-col>
            <v-col
                cols="12"
                md="8"
                sm="4">
                <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex">
                            <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Inicia a operação seguinte quando termina a atual: </v-label>
                            <v-checkbox-btn
                                color="primary"
                                v-model="workCenter.iniAuto"></v-checkbox-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col
                cols="12"
                md="8"
                sm="4">
                <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex">
                            <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Termina a operação anterior quando inicia a atual: </v-label>
                            <v-checkbox-btn
                                color="primary"
                                v-model="workCenter.endAuto"></v-checkbox-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Distribuição de tempos em operações realizadas em simultâneo: </v-label>
                <div>
                    <v-select
                        :items="distributionTypes"
                        item-title="title"
                        v-model:model-value="workCenter.timesDistributionTypes"
                        item-value="value">
                    </v-select>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { Ref, ref, computed } from 'vue';
    import { WorkCenter, WorkCenterSOC } from '@/types';
    import useRules from '@/composables/rules';
    import { VForm } from 'vuetify/lib/components/index.mjs';

    const form = ref<VForm>();

    const rules = useRules();
    const props = defineProps(['workCenter']);

    const workCenter = ref(props.workCenter);
    console.log(workCenter.value);

    const distributionTypes = ref([
        {title: 'Sem distribuição', value: 0},
        {title: 'Ponderação pelo tempo teórico', value: 1},
    ])

    // const distributionType = computed(() => {
    //     return distributionTypes.value.find((item) => item.value === workCenter.value.timesDistributionTypes);
    // });

    const onSubmit = () => {
        console.log('onSubmit');
    };
</script>

<style scoped></style>
