<template>
    <v-container
        class="flex-column justify-start"
        fluid>
        <v-row>
            <v-data-table
                :headers="headers"
                show-select
                v-model="selectedQuantitiesReservation"
                :items="quantitiesReservation"
                :item-value="(item) => item">
                <template v-slot:header.data-table-select> </template>
                <template v-slot:item.data-table-select="{ isSelected, select, item }">
                    <v-checkbox
                        class="checkbox"
                        :input-value="selectedQuantitiesReservation.includes(item)"
                        :disabled="pendingQuantity === 0 && !selectedQuantitiesReservation.includes(item)"
                        @change="toggleSelection(item)" />
                </template>
                <template v-slot:item.DataEntrega="{ item }">
                    <span>{{ formatDate(item.DataEntrega) }}</span>
                </template>
                <template v-slot:item.QuantSeleccionada="{ item }">
                    <span>{{ getSelectedQuantity(item) }}</span>
                </template>
            </v-data-table>
            <v-row class="tw-mt-2 tw-gap-x-6 tw-px-8 tw-text-sm">
                <p><strong>Qtd. Reservada:</strong> {{ selectedQuantitiesReservation.reduce((acc, item) => acc + item.QuantSeleccionada, 0) }} {{ need.Unidade }}</p>
                <p><strong>Qtd. Pendente:</strong> {{ pendingQuantity }} {{ need.Unidade }}</p>
            </v-row>
            <v-row>
                <v-col class="tw-flex tw-justify-end tw-pt-4">
                    <v-btn
                        color="primary"
                        class="mr-2"
                        @click="createReservation">
                        Confirmar
                    </v-btn>
                    <v-btn
                        color="red"
                        @click="closeDialog">
                        Fechar
                    </v-btn>
                </v-col>
            </v-row>
        </v-row>
        <v-row>
            <v-alert
                class="tw-mt-3"
                v-if="errorMessage"
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
            <v-alert
                class="tw-mt-3"
                v-if="warningMessage"
                type="warning"
                variant="tonal"
                density="compact"
                >{{ warningMessage }}
            </v-alert>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
    import { ref } from 'vue';
    import _, { set } from 'lodash';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { defineProps, defineEmits } from 'vue';
    import { createReservations } from '@/api/reservationsManager';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();

    const props = defineProps(['need', 'quantitiesReservation', 'dialog']);

    // Close button is in child component (this one)
    // So we need to emit an event to the parent component
    // To close the dialog
    const emit = defineEmits(['update:close', 'update:success']);

    const closeDialog = () => {
        emit('update:close', props.dialog);
    };

    const closeDialogAndRefresh = () => {
        emit('update:close', props.dialog);
        emit('update:success', true);
    };

    // Function to Generate Unique ID to Processed Calc Needs
    // We Need This To Use In The Item-Value Property Of The V-Data-Table
    // Otherwise, The Selection Will Not Work
    const generateUniqueID = () => {
        return `${Date.now().toString(36)}-${Math.random().toString(36).slice(2, 11)}`;
    };

    const need = ref(props.need);
    const quantitiesReservation = ref(
        props.quantitiesReservation.map((item: any) => ({
            ...item,
            ID: generateUniqueID(),
        })),
    );

    const selectedQuantitiesReservation = ref([]);

    const pendingQuantity = ref(need.value.QuantPend);

    const errorMessage = ref('');
    const warningMessage = ref('');

    const headers = ref([
        {
            title: 'Documento',
            key: 'Documento',
        },
        {
            title: 'Entidade',
            key: 'Entidade',
        },
        {
            title: 'Data Entrega',
            key: 'DataEntrega',
        },
        {
            title: 'Estado',
            key: 'Estado',
        },
        {
            title: 'Armazém',
            key: 'Armazem',
        },
        {
            title: 'Localização',
            key: 'Localizacao',
        },
        {
            title: 'Lote',
            key: 'Lote',
        },
        {
            title: 'Qtd. Original',
            key: 'Quantidade',
            align: 'end',
        },
        {
            title: 'Qtd. Disponível',
            key: 'QuantidadeDisp',
            align: 'end',
        },
        {
            title: 'Qtd. Sel.',
            key: 'QuantSeleccionada',
            align: 'end',
        },
    ]);

    const createReservation = async () => {
        if (selectedQuantitiesReservation.value.length === 0) {
            warningMessage.value = 'Selecione pelo menos uma necessidade para reservar.';
            setTimeout(() => {
                warningMessage.value = '';
            }, 5000);
            return;
        }
        const data = {
            Necessidade: need.value,
            Reservas: selectedQuantitiesReservation.value,
        }
        try {
            showLoader();
            await createReservations(data).then(() => {
                $alert.showAlert({
                    type: 'success',
                    text: 'Reserva(s) criada(s) com sucesso.',
                });
                closeDialogAndRefresh();
            });
        } catch (error) {
            console.error(error);
            errorMessage.value = error?.response?.data?.error?.message && error?.response?.data?.error?.status !== 500 ? error.response.data.error.message : 'Erro ao criar reserva.';
            setTimeout(() => {
                errorMessage.value = '';
            }, 5000);
            hideLoader();
        }
    };

    const toggleSelection = (item: any) => {
        // Verifica se o item já está selecionado
        const index = selectedQuantitiesReservation.value.indexOf(item);
        if (index === -1 && pendingQuantity.value > 0) {
            // Adiciona o item se a quantidade pendente permitir
            selectedQuantitiesReservation.value.push(item);
            const selectedQuantity = Math.min(item.QuantidadeDisp, pendingQuantity.value);

            item.QuantSeleccionada = selectedQuantity;
            pendingQuantity.value -= selectedQuantity;
        } else if (index !== -1) {
            // Remove o item se estiver selecionado e ajusta a quantidade pendente
            pendingQuantity.value += item.QuantSeleccionada;
            item.QuantSeleccionada = 0;
            selectedQuantitiesReservation.value.splice(index, 1);
        }
    };

    const getSelectedQuantity = (item: any) => {
        return item.QuantSeleccionada || 0;
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>
<style>
    .v-data-table__td--select-row .checkbox {
        display: flex !important;
    }
</style>
