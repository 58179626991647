// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth';

import Home from '@/views/Home.vue';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';

// PANEL
import Panel from '@/views/Panel/Panel.vue';

// WORK CENTERS
import WorkCenters from '@/views/WorkCenters/WorkCenters.vue';
import WorkCenter from '@/views/WorkCenters/WorkCenter.vue';
import WorkCenterDetails from '@/views/WorkCenters/WorkCenterDetails.vue';
import WorkCenterMachineCost from '@/views/WorkCenters/WorkCenterMachineCost.vue';
import WorkCenterLabor from '@/views/WorkCenters/WorkCenterLabor.vue';
import WorkCenterParameters from '@/views/WorkCenters/WorkCenterParameters.vue';
import WorkCenterTerminal from '@/views/WorkCenters/WorkCenterTerminal.vue';
import WorkCenterPlanning from '@/views/WorkCenters/WorkCenterPlanning.vue';
import CreateWorkCenter from '@/views/WorkCenters/CreateWorkCenter.vue';

// ARTICLES
import Articles from '@/views/Articles/Articles.vue';
import Article from '@/views/Articles/Article.vue';
import CreateArticle from '@/views/Articles/CreateArticle.vue';
import ArticleDetails from '@/views/Articles/ArticleDetails.vue';
import ArticleParentArticles from '@/views/Articles/ArticleParentArticles.vue';
import ArticleTechnicalSheet from '@/views/Articles/ArticleTechnicalSheet.vue';
import LoadArticles from '@/views/Articles/LoadArticles.vue';

// TECHNICAL SHEETS
import TechnicalSheets from '@/views/TechnicalSheets/TechnicalSheets.vue';
import TechnicalSheetDetail from '@/views/TechnicalSheets/TechnicalSheetDetail.vue';
import LoadInventorTechnicalSheet from '@/views/TechnicalSheets/LoadInventorTechnicalSheet.vue';
import CreateTechnicalSheet from '@/views/TechnicalSheets/CreateTechnicalSheet.vue';
import LoadOldTechnicalSheet from '@/views/TechnicalSheets/LoadOldTechnicalSheet.vue';

// CALC NEEDS
import CalcNeeds from '@/views/CalcNeeds/CalcNeeds.vue';
import CreateCalcNeeds from '@/views/CalcNeeds/CreateCalcNeeds.vue';
import CalcNeed from '@/views/CalcNeeds/CalcNeed.vue';

// PRODUCTION ORDERS
import ProductionOrders from '@/views/ProductionOrders/ProductionOrders.vue';
import ProductionOrder from '@/views/ProductionOrders/ProductionOrder.vue';
import CreateProductionOrder from '@/views/ProductionOrders/CreateProductionOrder.vue';
import ProductionOrderDetails from '@/views/ProductionOrders/ProductionOrderDetails.vue';
import ProductionOrderDocuments from '@/views/ProductionOrders/ProductionOrderDocuments.vue';
import Operations from '@/views/ProductionOrders/Operations.vue';
import Operation from '@/views/ProductionOrders/Operation.vue';
import OperationDetails from '@/views/ProductionOrders/OperationDetails.vue';
import OperationComponents from '@/views/ProductionOrders/OperationComponents.vue';
import Cuttings from '@/views/ProductionOrders/Cuttings.vue';
import ControlPanel from '@/views/ProductionOrders/ControlPanel.vue';

// INTERNAL DOCUMENTS
import InternalDocuments from '@/views/InternalDocuments/InternalDocuments.vue';
import InternalOrderServices from '@/views/InternalDocuments/InternalOrderService/InternalOrderServices.vue';
import CreateInternalOrderService from '@/views/InternalDocuments/InternalOrderService/CreateInternalOrderService.vue';
import InternalOrderService from '@/views/InternalDocuments/InternalOrderService/InternalOrderService.vue';
import InternalRequests from '@/views/InternalDocuments/InternalRequest/InternalRequests.vue';
import CreateInternalRequest from '@/views/InternalDocuments/InternalRequest/CreateInternalRequest.vue';
import InternalRequest from '@/views/InternalDocuments/InternalRequest/InternalRequest.vue';


// DAILY WORK
import CreateDailyWork from '@/views/DailyWork/CreateDailyWork.vue';
import DailyWorks from '@/views/DailyWork/DailyWorks.vue';
import DailyWork from '@/views/DailyWork/DailyWork.vue';
import DailyWorkDetails from '@/views/DailyWork/DailyWorkDetails.vue';
import DailyWorkDocuments from '@/views/DailyWork/DailyWorkDocuments.vue';

// RESERVATIONS MANAGER
import ReservationsManager from '@/views/ReservationsManager/ReservationsManager.vue';
import ReservationsManagerReservations from '@/views/ReservationsManager/Reservations.vue';
import ReservationsManagerNeeds from '@/views/ReservationsManager/Needs.vue';

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Home' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
            },
        ],
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/',
        component: Home,
        name: 'Home',
        redirect: { name: 'Panel' },
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: 'panel',
                name: 'Panel',
                component: Panel,
            },
            {
                path: 'work-centers',
                //name: 'WorkCenters',
                //component: WorkCenters,
                children: [
                    {
                        path: '',
                        name: 'WorkCenters',
                        component: WorkCenters,
                    },
                    {
                        path: 'create',
                        name: 'CreateWorkCenter',
                        component: CreateWorkCenter,
                    },
                    {
                        path: ':id',
                        name: 'WorkCenter',
                        component: WorkCenter,
                        redirect: { name: 'WorkCenterDetails' },
                        children: [
                            {
                                path: 'details',
                                name: 'WorkCenterDetails',
                                component: WorkCenterDetails,
                            },
                            {
                                path: 'machine-cost',
                                name: 'WorkCenterMachineCost',
                                component: WorkCenterMachineCost,
                            },
                            {
                                path: 'labor',
                                name: 'WorkCenterLabor',
                                component: WorkCenterLabor,
                            },
                            {
                                path: 'parameters',
                                name: 'WorkCenterParameters',
                                component: WorkCenterParameters,
                            },
                            {
                                path: 'terminal',
                                name: 'WorkCenterTerminal',
                                component: WorkCenterTerminal,
                            },
                            {
                                path: 'planning',
                                name: 'WorkCenterPlanning',
                                component: WorkCenterPlanning,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'articles',
                name: 'Articles',
                component: Articles,
            },
            {
                path: 'articles/create',
                name: 'CreateArticle',
                component: CreateArticle,
            },
            {
                path: 'articles/load',
                name: 'LoadArticles',
                component: LoadArticles,
            },
            {
                path: 'articles/:id',
                name: 'Article',
                component: Article,
                redirect: { name: 'ArticleDetails' },
                children: [
                    {
                        path: 'details',
                        name: 'ArticleDetails',
                        component: ArticleDetails,
                    },
                    {
                        path: 'technical-sheet',
                        name: 'ArticleTechnicalSheet',
                        component: ArticleTechnicalSheet,
                    },
                    {
                        path: 'parent-articles',
                        name: 'ArticleParentArticles',
                        component: ArticleParentArticles,
                    }
                ],
            },
            {
                path: 'technical-sheets',
                name: 'TechnicalSheets',
                component: TechnicalSheets,
            },
            {
                path: 'technical-sheets/create',
                name: 'CreateTechnicalSheet',
                component: CreateTechnicalSheet,
            },
            {
                path: 'technical-sheets/:id',
                name: 'TechnicalSheetDetail',
                component: TechnicalSheetDetail,
            },
            {
                path: 'technical-sheets/load/inventor',
                name: 'LoadInventorTechnicalSheet',
                component: LoadInventorTechnicalSheet,
            },
            {
                path: 'calc-needs',
                name: 'CalcNeeds',
                component: CalcNeeds,
            },
            {
                path: 'calc-needs/create',
                name: 'CreateCalcNeeds',
                component: CreateCalcNeeds,
            },
            {
                path: 'calc-needs/:id',
                name: 'CalcNeed',
                component: CalcNeed,
            },
            {
                path: 'production-orders',
                name: 'ProductionOrders',
                component: ProductionOrders,
            },
            {
                path: 'production-orders/operations',
                name: 'Operations',
                component: Operations,
            },
            {
                path: 'production-orders/cuttings',
                name: 'Cuttings',
                component: Cuttings,
            },
            {
                path: 'production-orders/control-panel',
                name: 'ControlPanel',
                component: ControlPanel,
            },
            {
                path: 'production-orders/operations/:operationId',
                name: 'Operation',
                component: Operation,
                redirect: { name: 'OperationDetails' },
                children: [
                    {
                        path: 'details',
                        name: 'OperationDetails',
                        component: OperationDetails,
                    },
                    {
                        path: 'components',
                        name: 'OperationComponents',
                        component: OperationComponents,
                    }
                ]
            },
            {
                path: 'production-orders/create',
                name: 'CreateProductionOrder',
                component: CreateProductionOrder,
            },
            {
                path: 'production-orders/:id',
                name: 'ProductionOrder',
                component: ProductionOrder,
                redirect: { name: 'ProductionOrderDetails' },
                children: [
                    {
                        path: 'details',
                        name: 'ProductionOrderDetails',
                        component: ProductionOrderDetails,
                    },
                    {
                        path: 'documents',
                        name: 'ProductionOrderDocuments',
                        component: ProductionOrderDocuments,
                    }
                ],
            },
            {
                path: 'internal-documents/OSI',
                name: 'InternalOrderServices',
                component: InternalOrderServices
            },
            {
                path: 'internal-documents/OSI/create',
                name: 'CreateInternalOrderService',
                component: CreateInternalOrderService
            },
            {
                path: 'internal-documents/OSI/:id',
                name: 'InternalOrderService',
                component: InternalOrderService
            },
            {
                path: 'internal-documents/RI',
                name: 'InternalRequests',
                component: InternalRequests
            },
            {
                path: 'internal-documents/RI/create',
                name: 'CreateInternalRequest',
                component: CreateInternalRequest
            },
            {
                path: 'internal-documents/RI/:id',
                name: 'InternalRequest',
                component: InternalRequest
            },
            {
                path: 'daily-works',
                name: 'DailyWorks',
                component: DailyWorks
            },
            {
                path: 'daily-works/create',
                name: 'CreateDailyWork',
                component: CreateDailyWork
            },
            {
                path: 'daily-works/:id',
                name: 'DailyWork',
                component: DailyWork,
                redirect: { name: 'DailyWorkDetails' },
                children: [
                    {
                        path: 'details',
                        name: 'DailyWorkDetails',
                        component: DailyWorkDetails,
                    },
                    {
                        path: 'documents',
                        name: 'DailyWorkDocuments',
                        component: DailyWorkDocuments,
                    }
                ]
            },
            {
                path: 'reservations-manager',
                name: 'RerservationsManager',
                component: ReservationsManager,
                redirect: { name: 'Reservations' },
                children: [
                    {
                        path: 'reservations',
                        name: 'Reservations',
                        component: ReservationsManagerReservations
                    },
                    {
                        path: 'needs',
                        name: 'Needs',
                        component: ReservationsManagerNeeds,
                    },
                ],
            },
        ],
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((route) => route.meta.authRequired);

    if (authRequired) {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
            next({ name: 'Login' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
