<template>
    <v-row>
        <v-col>
            <v-data-table
                :hover="true"
                @click:row="handleClick"
                :items="parentArticles"></v-data-table>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import { defineProps, computed } from 'vue';

    const emit = defineEmits(['drilldown:article']);
    const props = defineProps(['article', 'families', 'articleTypes', 'units', 'articleOperations', 'parentArticles']);

    const parentArticles = computed(() => {
        return props.parentArticles.map((parentArticle: any) => {
            return {
                Artigo: parentArticle.Artigo,
                Quantidade: parentArticle.Consumo,
                Unidade: parentArticle.UnidadeAlternativa,
            };
        });
    });

    const handleClick = (event: any, row: any) => {
        console.log('Clicked item: ', row.item);
        emit('drilldown:article', row.item.Artigo);
    };
</script>

<style scoped></style>
