import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/reservations-manager';

export async function getReservations(type: string | null = null, page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<any> {
    return axios.get(`${API_REF}/${type}`, {
        params: {
            page,
            limit,
            search,
            filterByIds,
        },
    });
}

export async function createReservations(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/needs/create-many`, data);
}

// Anula a Reserva
export async function deleteReservation(id: number): AxiosPromise<any> {
    return axios.delete(`${API_REF}/reservations/${id}`);
}

// Anula as Reservas
export async function deleteReservations(ids: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/reservations/delete-many`, ids);
}

// Vai buscar as quantidades para a reserva, para uma determinada necessidade
export async function getQuantitiesReservation(docId: string, article: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/needs/quantities-reservation`, {
        params: {
            docId,
            article,
        },
    });
}

export async function suggestAutomaticReservations(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}/needs/suggest-automatic-reservations`, data);
}
