<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ workCenter?.name }}</h1>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn
                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="x-large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
        </v-row>
        <router-view
            v-if="done"
            :workCenter="workCenter"
            :sections="sections"
            :workCalendars="workCalendars"
            :hoursTypes="hoursTypes"
            :costsTypes="costsTypes"
            :costCalculationTypes="costCalculationTypes"
            :workers="workers"
            :warehouses="warehouses"
            :warehouseLocations="warehouseLocations" />
    </v-container>
</template>

<script setup lang="ts">
    import { WorkCenterSOC, Section, WorkCalendar } from '@/types';
    import { getWorkCenter } from '@/api/workCenters';
    import { ref, computed } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import usePrimaveraParser from '@/composables/primaveraParser';
    import { getSections } from '@/api/sections';
    import { getWorkCalendars } from '@/api/workCalendars';
    import { getHoursTypes } from '@/api/hoursTypes';
    import { getCostsTypes } from '@/api/costsTypes';
    import { getCostCalculationTypes } from '@/api/costCalculationTypes';
    import { getWorkers } from '@/api/workers';
    import { getWarehouses } from '@/api/warehouses';
    import { getWarehouseLocations } from '@/api/warehouseLocations';

    const { showLoader, hideLoader } = useLoader();
    const $router = useRouter();
    const $route = useRoute();
    const { parseSocToPrimaveraWorkCenter, parsePrimaveraToSocWorkCenter } = usePrimaveraParser();

    const done = ref(false);

    const workCenter = ref<WorkCenterSOC | null>(null);

    const sections = ref([] as Section[]);
    const workCalendars = ref([] as WorkCalendar[]);
    const hoursTypes = ref([]);
    const costsTypes = ref([]);
    const costCalculationTypes = ref([]);
    const workers = ref([]);
    const warehouses = ref([]);
    const warehouseLocations = ref([]);

    const navItems = computed(() => [
        { title: 'Detalhes', to: { name: 'WorkCenterDetails', params: { id: $route.params.id } } },
        { title: 'Custo Máquina', to: { name: 'WorkCenterMachineCost', params: { id: $route.params.id } } },
        { title: 'Mão de Obra', to: { name: 'WorkCenterLabor', params: { id: $route.params.id } } },
        { title: 'Parâmetros', to: { name: 'WorkCenterParameters', params: { id: $route.params.id } } },
        { title: 'Terminal de Recolha', to: { name: 'WorkCenterTerminal', params: { id: $route.params.id } } },
        { title: 'Planeamento', to: { name: 'WorkCenterPlanning', params: { id: $route.params.id } } },
    ]);

    async function init() {
        showLoader();
        console.log('init');
        try {
            const [workCenterResponse,sectionsResponse, workCalendarsResponse, hoursTypesResponse, costsTypesResponse, costCalculationTypesResponse, workersResponse, warehousesResponse, warehouseLocationsResponse ] = await Promise.all([getWorkCenter(Number($route.params.id)), getSections(), getWorkCalendars(), getHoursTypes(), getCostsTypes(), getCostCalculationTypes(), getWorkers(), getWarehouses(), getWarehouseLocations()]);
            workCenter.value = parsePrimaveraToSocWorkCenter(workCenterResponse.data);
            sections.value = sectionsResponse.data;
            workCalendars.value = workCalendarsResponse.data;
            hoursTypes.value = hoursTypesResponse.data;
            costsTypes.value = costsTypesResponse.data;
            costCalculationTypes.value = costCalculationTypesResponse.data;
            workers.value = workersResponse.data;
            warehouses.value = warehousesResponse.data;
            warehouseLocations.value = warehouseLocationsResponse.data;
        } catch (error) {
            console.error(error);
        } finally {
            done.value = true;
            hideLoader();
        }
    }

    init();
</script>

<style scoped></style>
