<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('workCenters.createWorkCenter') }}</h1>
            </v-col>
        </v-row>
        <v-form
            ref="form"
            @submit.prevent="onSubmit">
            <v-row class="tw-mt-3">
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.name') }}
                    </v-label>
                    <div>
                        <v-text-field
                            :rules="[rules.required]"
                            v-model="data.name"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.description') }}
                    </v-label>
                    <div>
                        <v-text-field
                            :rules="[rules.required]"
                            v-model="data.description"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.section') }}
                    </v-label>
                    <div>
                        <v-select
                            :items="sections"
                            :rules="[rules.required]"
                            item-title="Seccao"
                            v-model:model-value="data.section"
                            item-value="IDSeccao">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.nOrder') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            v-model="data.nOrder"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.workCalendar') }}
                    </v-label>
                    <div>
                        <v-select
                            :rules="[rules.required]"
                            :items="workCalendars"
                            item-title="CalendarioTrabalho"
                            v-model:model-value="data.workCalendar"
                            item-value="CalendarioTrabalho">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> {{ $t('workCenters.fields.nShifts') }} </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            v-model="data.nShifts"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.capacityFactor') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :rules="[rules.positive]"
                            v-model="data.capacityFactor"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.efficiency') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :rules="[rules.positive]"
                            v-model="data.efficiency"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12">
                    <h1 class="tw-text-xl tw-font-bold">Tempos</h1>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.preparationTime') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            v-model="data.preparationTime"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.waitingTime') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            v-model="data.waitingTime"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12">
                    <h1 class="tw-text-xl tw-font-bold">Custos Hora</h1>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="3"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.calculatedMachineCost') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            v-model="data.calculatedMachineCost"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="3"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.reviewedMachineCost') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            v-model="data.reviewedMachineCost"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="3"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.calculatedOperatorCost') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            v-model="data.calculatedOperatorCost"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="3"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.reviewedOperatorCost') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            v-model="data.reviewedOperatorCost"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="9"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('workCenters.fields.obs') }}
                    </v-label>
                    <div>
                        <v-textarea v-model="data.obs"></v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="tw-flex tw-items-end tw-justify-end">
                    <v-btn
                        color="primary"
                        type="submit"
                        >{{ $t('shared.save') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import useRules from '@/composables/rules';
    import { useLoader } from '@/composables/useLoader';
    import usePrimavera from '@/composables/primaveraParser';
    import { Section, WorkCalendar, WorkCenter, WorkCenterSOC } from '@/types';
    import { createWorkCenter } from '@/api/workCenters';
    import { useRouter } from 'vue-router';
    import { getSections } from '@/api/sections';
    import { getWorkCalendars } from '@/api/workCalendars';
    import { VForm } from 'vuetify/lib/components/index.mjs';

    const { showLoader, hideLoader } = useLoader();
    const { parseSocToPrimaveraWorkCenter } = usePrimavera();
    const rules = useRules();
    const $router = useRouter();

    const form = ref<VForm>();
    const data = ref({
        name: '',
        description: '',
        section: null,
        nOrder: 0,
        workCalendar: null,
        nShifts: 0,
        capacityFactor: 0,
        efficiency: 0,
        preparationTime: 0,
        waitingTime: 0,
        calculatedMachineCost: 0,
        reviewedMachineCost: 0,
        calculatedOperatorCost: 0,
        reviewedOperatorCost: 0,
        obs: '',
        nOperators: 0,
        warehouse: null,
        registerQuantitiesInInit: false,
        registerQuantitiesInEnd: false,
        registerQuantitiesDuring: false,
        iniAuto: false,
        endAuto: false,
        location: '',
        parallelPrograming: false,
        componentWarehouse: '',
        componentWarehouseLocation: '',
        productEntranceWarehouse: '',
        productEntranceWarehouseLocation: '',
        rejectedProductWarehouse: '',
        rejectedProductWarehouseLocation: '',
        automaticOperationsPlanning: true,
        isActivatedLoadLimit: false,
        maxLoadLimit: 0,
        timesDistributionTypes: 0,
    });

    const sections = ref([] as Section[]);
    const workCalendars = ref([] as WorkCalendar[]);

    async function init() {
        showLoader();
        try {
            const [sectionsResponse, workCalendarsResponse] = await Promise.all([getSections(), getWorkCalendars()]);
            sections.value = sectionsResponse.data;
            workCalendars.value = workCalendarsResponse.data;
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    }

    init();

    const onSubmit = async () => {
        if (!form.value) return;
        const { valid } = await form.value.validate();
        if (!valid) return;

        try {
            showLoader();
            const parsedBody = (await parseSocToPrimaveraWorkCenter(data.value)) as WorkCenter;
            console.log(parsedBody);
            await createWorkCenter(parsedBody);

            $router.push({ name: 'WorkCenters' });
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    };
</script>

<style scoped></style>
