<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3 tw-px-3">
            <v-col>
                <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                    <hot-table
                        :data="workCenter.machineCosts"
                        :licenseKey="license"
                        :contextMenu="true"
                        :columns="columns"
                        :rowHeaders="false"
                        :stretchH="'all'"
                        :width="'100%'"
                        :colHeaders="tableHeaders"
                        ref="hotTableComponent"></hot-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import _ from 'lodash';
    import { WorkCenter, WorkCenterSOC } from '@/types';
    import useRules from '@/composables/rules';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';
    import Handsontable from 'handsontable';

    const form = ref<VForm>();

    registerAllModules();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);
    const hotTableComponent = ref(null);

    const props = defineProps(['workCenter', 'hoursTypes', 'costsTypes', 'costCalculationTypes']);

    const workCenter = ref(props.workCenter);
    const hoursTypes = ref(props.hoursTypes);
    const costsTypes = ref(props.costsTypes);
    const costCalculationTypes = ref(props.costCalculationTypes);

    const tableHeaders = ref(['Tipo Cálculo', 'Tipo Hora', 'Tipo Custo', 'Custo', 'Qtd.', 'Total']);

    const createDropdownSource = (array: any, key: any) => (workCenter.value ? array.map((item: any) => item[key]) : []);

    const columns = computed(() => {
        return [
            {
                data: 'IDTipoCalculoCusto',
                type: 'dropdown',
                source: createDropdownSource(costCalculationTypes.value, 'Descricao'),
                validator: function (value, callback) {
                    if (_.isString(value)) {
                        value = costCalculationTypes.value.find((item: any) => item.Descricao === value)?.IDTipoCalculoCusto;
                    }
                    // Check if the value is empty (required field), bigger than 0 and less than the last index of the array
                    callback(!!value && value > 0 && value <= costCalculationTypes.value.length);
                },
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    if (_.isInteger(value)) {
                        td.innerHTML = costCalculationTypes.value.find((item: any) => item.IDTipoCalculoCusto === value)?.Descricao;
                    }
                    if (_.isString(value)) {
                        value = costCalculationTypes.value.find((item: any) => item.Descricao === value)?.IDTipoCalculoCusto;
                    }
                    workCenter.value.machineCosts[row].IDTipoCalculoCusto = value;
                },
            },
            {
                data: 'IDTipoHora',
                type: 'dropdown',
                source: createDropdownSource(hoursTypes.value, 'Descricao'),
                validator: function (value, callback) {
                    if (_.isString(value)) {
                        value = hoursTypes.value.find((item: any) => item.Descricao === value)?.IDTipoHora;
                    }
                    // Check if the value is empty (required field), bigger than 0 and less than the last index of the array
                    callback(!!value && value > 0 && value <= hoursTypes.value.length);
                },
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    if (_.isInteger(value)) {
                        td.innerHTML = hoursTypes.value.find((item: any) => item.IDTipoHora === value)?.Descricao;
                    }
                    if (_.isString(value)) {
                        value = hoursTypes.value.find((item: any) => item.Descricao === value)?.IDTipoHora;
                    }
                    workCenter.value.machineCosts[row].IDTipoHora = value;
                },
            },
            {
                data: 'IDTipoCusto',
                type: 'dropdown',
                source: createDropdownSource(costsTypes.value, 'Descricao'),
                validator: function (value, callback) {
                    if (_.isString(value)) {
                        value = costsTypes.value.find((item: any) => item.Descricao === value)?.IDTipoCusto;
                    }
                    // Check if the value is empty (required field), bigger than 0 and less than the last index of the array
                    callback(!!value && value > 0 && value <= costsTypes.value.length);
                },
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    if (_.isInteger(value)) {
                        td.innerHTML = costsTypes.value.find((item: any) => item.IDTipoCusto === value)?.Descricao;
                    }
                    if (_.isString(value)) {
                        value = costsTypes.value.find((item: any) => item.Descricao === value)?.IDTipoCusto;
                    }
                    workCenter.value.machineCosts[row].IDTipoCusto = value;
                },
            },
            {
                data: 'Custo',
                type: 'numeric',
                format: '0,0.00',
            },
            {
                data: 'Qt',
                type: 'numeric',
                format: '0,0.00',
            },
            {
                data: 'Total',
                type: 'numeric',
                format: '0,0.00',
                readOnly: true,
                renderer: function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.NumericRenderer.apply(this, arguments);
                    const custo = instance.getDataAtCell(row, 3);
                    const qt = instance.getDataAtCell(row, 4);
                    const result = qt !== 0 && custo !== null && qt !== null ? (custo / qt).toFixed(5) : '0.00000';
                    td.innerHTML = result;
                },
            },
        ];
    });

    const onSubmit = () => {
        console.log('onSubmit');
        console.log(workCenter.value);
    };
</script>

<style scoped>
    .hot-table-container {
        height: 300px;
    }
</style>
