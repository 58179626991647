<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">Listas de Corte</h1>
            </v-col>
            <!-- <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    color="primary"
                    @click="$router.push({ name: 'CreateProductionOrder' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col> -->
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-download"
                    @click="openDialog"></v-btn>
                <!-- <v-btn

                    size="large"
                    density="comfortable"
                    class="text-gray tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                :headers="headers"
                :items="productionOrders"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                show-expand
                v-model:expanded="expanded"
                show-select
                v-model="selectedItems"
                @click:row="onRowClick"
                item-value="OrdemFabrico">
                <!-- filters -->
                <template v-slot:header.CDU_CodigoProjeto="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isMenuOpen"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchProject"
                                            @update:model-value="onSearchProject"
                                            clearable
                                            label="Pesquisar"
                                            variant="outlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedProjects"
                                    :key="item">
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedProjects"
                                                :value="item"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllProjects()"
                                                color="primary"
                                                v-model="allProjectsSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <!-- expanded rows -->
                <template v-slot:expanded-row="{ columns, item }">
                    <tr
                        v-if="item?.operations?.length > 0"
                        class="tw-bg-gray-100">
                        <td :colspan="columns.length">
                            <div class="tw-py-2">
                                <!-- Headers -->
                                <v-row class="tw-items-center tw-font-bold tw-text-secondary">
                                    <v-col>
                                        <p class="tw-text-sm">Sequência</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Operacão</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Descrição</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Confirmada</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Centro Trabalho</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Descrição CT</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Estado</p>
                                    </v-col>
                                </v-row>
                                <!-- <v-divider class="tw-py-1"></v-divider> -->
                                <!-- Rows -->
                                <v-row
                                    class="tw-cursor-pointer tw-items-center"
                                    v-for="(operation, index) in item?.operations"
                                    :key="operation.IDOrdemFabricoOperacao"
                                    @click="onExpandedRowClick(operation)">
                                    <v-col>
                                        <p class="tw-text-sm tw-text-secondary">{{ operation.Operacao }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm tw-text-secondary">{{ operation.OperacaoProducao }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm tw-text-secondary">{{ operation.Descricao }}</p>
                                    </v-col>
                                    <v-col>
                                        <v-icon
                                            class=""
                                            :color="operation.Confirmada ? 'success' : 'error'">
                                            {{ operation.Confirmada ? 'mdi-check' : 'mdi-close' }}
                                        </v-icon>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm tw-text-secondary">{{ workCenter(operation.IDCentroTrabalho).name }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm tw-text-secondary">{{ workCenter(operation.IDCentroTrabalho).description }}</p>
                                    </v-col>
                                    <v-col>
                                        <v-chip
                                            class=""
                                            size="default"
                                            :color="operation.Estado == 7 ? 'secondary' : operation.Estado == 8 ? 'warning' : operation.Estado == 9 ? 'success' : 'grey'"
                                            dark>
                                            {{ operation.Estado == 7 ? 'Por Iniciar' : operation.Estado == 8 ? 'Em Curso' : operation.Estado == 9 ? 'Terminada' : 'Desconhecido' }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </div>
                        </td>
                    </tr>
                </template>
                <template v-slot:item.DataOrdemFabrico="{ item }">
                    <span>{{ formatDate(item.DataOrdemFabrico) }}</span>
                </template>
                <template v-slot:item.Confirmada="{ item }">
                    <v-icon :color="item.Confirmada ? 'success' : 'error'">
                        {{ item.Confirmada ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:item.Estado="{ item }">
                    <v-chip
                        size="default"
                        :color="item.Estado == 2 ? 'secondary' : item.Estado == 3 ? 'warning' : item.Estado == 4 ? 'error' : item.Estado == 5 ? 'success' : 'grey'"
                        dark>
                        {{ item.Estado == 2 ? 'Por Iniciar' : item.Estado == 3 ? 'Em Curso' : item.Estado == 4 ? 'Suspensa' : item.Estado == 5 ? 'Terminada' : 'Desconhecido' }}
                    </v-chip>
                </template>
                <template v-slot:item.Descricao="{ item }">
                    <span>{{ description(item.Artigo) }}</span>
                </template>
                <template v-slot:item.DataInicio="{ item }">
                    <!-- Estado 5 = Terminada -->
                    <span v-if="item.Estado == 5">{{ formatDate(item.DataIniReal) }}</span>
                    <!-- Estado 2 = Por Iniciar | Estado 3 = Em Curso -->
                    <span v-else>{{ formatDate(item.DataIniPrevista) }}</span>
                </template>
                <template v-slot:item.DataFim="{ item }">
                    <!-- Estado 5 = Terminada -->
                    <span v-if="item.Estado == 5">{{ formatDate(item.DataFimReal) }}</span>
                    <!-- Estado 2 = Por Iniciar | Estado 3 = Em Curso -->
                    <span v-else>{{ formatDate(item.DataFimPrevista) }}</span>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
    <!-- Pop-up Pré Criação da Lista de Corte -->
    <v-dialog
        v-model="dialog"
        max-width="1400">
        <v-card class="tw-p-6">
            <v-card-title>
                <div class="tw-flex tw-items-center tw-gap-x-4">
                    <v-icon class="tw-rounded-md tw-bg-primary tw-p-5 tw-text-white">mdi-scissors-cutting</v-icon>
                    <span class="text-h5 tw-font-bold">Gerar Lista de Corte</span>
                </div></v-card-title
            >
            <v-card-text class="tw-mt-6">
                <v-combobox
                    class="combobox-chips"
                    v-model="selectedOperationsNoDuplicates"
                    :items="operationsNoDuplicates"
                    label="Operações a incluir"
                    variant="outlined"
                    chips
                    clearable
                    :rules="[rules.required]"
                    multiple>
                    <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                            v-bind="attrs"
                            :model-value="selected"
                            closable
                            @click="select"
                            @click:close="">
                            {{ item }}
                        </v-chip>
                    </template>
                </v-combobox>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    variant="text"
                    @click="dialog = !dialog">
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    variant="text"
                    @click="onConfirm">
                    Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { getProductionOrders, generateCuttingLists } from '@/api/productionOrders';
    import { getArticles } from '@/api/articles';
    import { getWorkCenters } from '@/api/workCenters';
    import { ref, watch } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useChunk } from '@/composables/useChunk';
    import useRules from '@/composables/rules';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import * as XLSX from 'xlsx';
    import _ from 'lodash';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();

    const $router = useRouter();
    const $route = useRoute();

    const rules = useRules();

    const initialQuery = ref(true);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const dialog = ref(false);

    const selectedOperations = ref<{ Artigo: any; Descricao: any; Operacao: any }[]>([]);
    // These two variables are used to show the operations in the combobox, that's why we need to remove the duplicates
    const selectedOperationsNoDuplicates = ref<{ Artigo: any; Descricao: any; Operacao: any }[]>([]);
    const operationsNoDuplicates = ref<{ Artigo: any; Descricao: any; Operacao: any }[]>([]);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref<number>(1);
    const total = ref<number>(0);
    const pageSize = ref<number>(10);
    const expanded = ref<any[]>([]);
    const selectedItems = ref<any[]>([]);

    const productionOrders = ref<any[]>([]);
    const articles = ref<any[]>([]);
    const workCenters = ref<any[]>([]);
    const projects = ref<any[]>([]);

    const headers = ref([
        { title: 'Ordem de Fabrico', key: 'OrdemFabrico' },
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Projeto', key: 'CDU_CodigoProjeto' },
        { title: 'Data', key: 'DataOrdemFabrico' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Confirmada', key: 'Confirmada' },
        { title: 'Valorizada', key: '' },
        { title: 'Estado', key: 'Estado' },
        { sortable: false, key: 'actions' },
    ]);

    const isMenuOpen = ref(false);

    // Declare Filters Variables
    //
    // Projects
    const selectedProjects = ref<string[]>([]);
    const allProjectsSelected = ref(false);
    // Searched Projects Is An Array Of Projects
    // That Will Be Used To Handle The Search In Filters Menu, So It's Value Will Depend On The Search Input
    // Projects Array Is The Variable That Contains All The Projects, And It's Value Will Not Change
    const searchedProjects = ref<any[]>([]);
    const searchProject = ref('');

    const filterByIds = ref<any>(null);

    async function onRowClick(row: any, o: any) {
        if (expanded.value.includes(o.item.OrdemFabrico)) {
            expanded.value = expanded.value.filter((item) => item !== o.item.OrdemFabrico);
        } else {
            expanded.value.push(o.item.OrdemFabrico);
        }
    }

    async function onExpandedRowClick(item: any) {
        $router.push({ name: 'Operation', params: { operationId: item.IDOrdemFabricoOperacao } });
    }

    // On Search Functions
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function onSearchProject(str: string) {
        if (allProjectsSelected.value && searchedProjects.value.length !== projects.value.length) allProjectsSelected.value = false;
        else if (!allProjectsSelected.value && searchedProjects.value.length == projects.value.length) allProjectsSelected.value = true;

        searchProject.value = str ?? '';

        searchedProjects.value = [...new Set(projects.value.filter((project) => project.toLowerCase().includes(searchProject.value.toLowerCase())))];
    }

    async function openDialog() {
        if (selectedItems.value.length == 0) {
            $alert.showAlert({
                type: 'warning',
                text: 'Selecione pelo menos uma Ordem de Fabrico para Gerar a Lista de Corte.',
            });
            return;
        } else {
            showLoader();
            try {
                await getProductionOrders(null, null, null, { OrdemFabrico: selectedItems.value }).then((response) => {
                    selectedOperations.value = response?.data?.data
                        .map((po) => po?.operations.map((operation) => ({ Artigo: po.Artigo, Descricao: operation.Descricao, Operacao: operation.Operacao })))
                        .flat();

                    selectedOperationsNoDuplicates.value = selectedOperations.value
                        .filter((operation, index, self) => index === self.findIndex((t) => t.Descricao === operation.Descricao))
                        .map((operation) => operation.Descricao);
                    operationsNoDuplicates.value = selectedOperationsNoDuplicates.value;
                });
            } catch (error) {
                console.error(error);
                hideLoader();
            } finally {
                dialog.value = true;
                hideLoader();
            }
        }
    }

    // Select All Projects Function
    async function selectAllProjects() {
        if (selectedProjects.value.length == searchedProjects.value.length) (selectedProjects.value = []), (allProjectsSelected.value = false);
        else (selectedProjects.value = searchedProjects.value), (allProjectsSelected.value = true);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    async function init() {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }

        try {
            await getProductionOrders(_page, _pageSize, search.value)
                .then(async (response) => {
                    productionOrders.value = response.data.data;
                    total.value = response.data.total;

                    const articlesIds = productionOrders.value.map((po) => po.Artigo);

                    //use chunk to avoid 413 error on headers
                    const chunkedArticlesIds = useChunk(articlesIds, 100);

                    for (let i = 0; i < chunkedArticlesIds.length; i++) {
                        const filterByIds = {
                            Artigo: chunkedArticlesIds[i],
                        };
                        const articlesResponse = await getArticles(null, null, null, filterByIds);
                        articles.value = [...articles.value, ...articlesResponse.data.data];
                    }

                    const workCentersResponse = await getWorkCenters(null, null, null, null);
                    workCenters.value = workCentersResponse.data.data;
                })
                .finally(() => {
                    initialQuery.value = false;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            hideLoader();
        }
    }

    init();

    async function getProjects() {
        try {
            await getProductionOrders(null, null, null, null)
                .then((response) => {
                    projects.value = response.data?.data.map((po) => po.CDU_CodigoProjeto).filter((project) => project !== null);
                    searchedProjects.value = [...new Set(projects.value)];
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    }

    getProjects();

    async function onConfirm() {
        showLoader();
        const body = selectedOperations.value.filter((operation) => selectedOperationsNoDuplicates.value.includes(operation.Descricao));
        try {
            await generateCuttingLists(body)
                .then((response) => {
                    const data = response?.data;
                    // If there are no cuttings to generate
                    if (data.length == 0) {
                        $alert.showAlert({
                            type: 'warning',
                            text: 'Não existem cortes a gerar.',
                        });
                        return;
                    }

                    // Else Generate Excel file
                    const ws = data.map((item: any) => ({
                        Artigo: item.Artigo,
                        'Descrição Artigo': item.DescricaoArtigo,
                        Operação: item.Operacao,
                        Componente: item.Componente,
                        'Descrição Componente': item.DescricaoComponente,
                        'Medida Corte': item.CDU_MedidaCorte,
                        'Medida Final': item.CDU_MedidaFinal,
                    }));
                    const wb = XLSX.utils.book_new();
                    const wsName = 'Lista de Corte';
                    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(ws), wsName);
                    XLSX.writeFile(wb, 'Lista de Corte.xlsx');
                })
                .finally(() => {
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao gerar a lista de corte',
            });
            hideLoader();
            dialog.value = false;
        }
    }

    // Watchers
    watch(
        () => $route.query,
        () => {
            if (initialQuery.value == false) {
                // search.value = $route.query.search as string ?? '';
                init();
            }
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        init();
    });

    watch(selectedProjects, async () => {
        if (selectedProjects.value.length !== searchedProjects.value.length) allProjectsSelected.value = false;
        else allProjectsSelected.value = true;

        filterByIds.value = selectedProjects.value.length > 0 ? { CDU_CodigoProjeto: selectedProjects.value } : null;

        await getProductionOrders(1, pageSize.value, search.value, filterByIds.value)
            .then((response) => {
                productionOrders.value = response.data.data;
                total.value = response.data.total;
            })
            .catch((error) => {
                console.error(error);
            });
    });

    // Return "Descricao" of an article
    const description = (articleId: string) => {
        const article = articles.value.find((a) => a.Artigo == articleId);
        return article?.Descricao ?? '';
    };

    // Return "Centro Trabalho" of an operation
    const workCenter = (workCenterId: any) => {
        const workCenter = workCenters.value.find((wc) => wc.IDCentroTrabalho == workCenterId);
        return workCenter ? { name: workCenter?.CentroTrabalho, description: workCenter?.Descricao } : { name: '', description: '' };
    };

    // Format time (e.g. 180 minutes to 3:00)
    const formatTime = (time: number) => {
        // Round the time value to 2 decimal places
        const roundedTime = parseFloat(time.toFixed(2));
        const hours = Math.floor(roundedTime / 60);
        const minutes = Math.floor(roundedTime % 60);

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style></style>
